import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Provider, SimpleClose } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { ProviderButton } from '../ProviderButton';
import { SearchIcon } from 'src/components/svg';
import { InputSearch } from 'src/components/Inputs/InputSearch';
import { RecentSearches } from '../../../RecentSearches';
import { NoGamesStub } from '../../../NoGamesStub';

interface ProvidersSearchResults {
  isFetching: boolean;
  providers: Provider[];
}

const PROVIDERS_SEARCH_LC_KEY = 'providersSearchResults';

export const ProvidersSearchResults: FC<ProvidersSearchResults> = ({ isFetching, providers }) => {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useLayoutEffect(() => {
    const dataFromLocalStorage = localStorage.getItem(PROVIDERS_SEARCH_LC_KEY);
    const parsedResult = dataFromLocalStorage && JSON.parse(dataFromLocalStorage);
    const savedRecentSearches = Array.isArray(parsedResult) ? parsedResult : [];

    if (savedRecentSearches.length) {
      setRecentSearches(savedRecentSearches);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(PROVIDERS_SEARCH_LC_KEY, JSON.stringify(recentSearches));
  }, [recentSearches]);

  const providersToRender = useMemo(
    () =>
      providers?.filter((provider) =>
        provider.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, providers]
  );

  const addRecentSearchItem = (item: string) => {
    if (providersToRender.length && item.length >= 2) {
      const items = new Set(recentSearches);
      items.add(item);
      setRecentSearches(Array.from(items));
    }
  };

  const handleRemoveItem = (item: string) => {
    const items = new Set(recentSearches);
    items.delete(item);
    setRecentSearches(Array.from(items));
  };

  const handleItemClick = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleInputBlur = () => {
    if (searchValue && providersToRender.length) {
      addRecentSearchItem(searchValue);
    }
  };

  const { t } = useTranslation();
  return (
    <div>
      <InputSearch
        placeholder={`${t('general.searchProvider')}`}
        value={searchValue}
        onChange={setSearchValue}
        leftIcon={<SearchIcon className="w-5 h-5" fill="#3C404A" />}
        className="flex bg-white items-center rounded-[20px] px-5 py-[13px] w-full mt-2.5"
        classNameInput="text-[#3C404A] text-[16px] px-2.5 focus:outline-none w-full"
        onBlur={handleInputBlur}
        rightIcon={
          searchValue.length ? (
            <SimpleClose
              onShow={() => {
                setSearchValue('');
              }}
              className="w-[11px] h-[11px]"
            />
          ) : null
        }
      />
      {!searchValue && Boolean(recentSearches?.length) && (
        <RecentSearches
          items={recentSearches}
          handleDelete={handleRemoveItem}
          handleClick={handleItemClick}
        />
      )}
      {searchValue && Boolean(providersToRender.length) && (
        <>
          <h3 className="text-text-secondary font-bold text-base mt-3">
            {t('general.searchResults')}
          </h3>
          <div className="mt-2 grid grid-cols-2 gap-2 568:grid-cols-3">
            {providersToRender?.map((provider) => (
              <ProviderButton provider={provider} key={provider.id} />
            ))}
          </div>
        </>
      )}

      {!isFetching && providersToRender.length === 0 && (
        <NoGamesStub>{t('general.noProvidersFound')}</NoGamesStub>
      )}
    </div>
  );
};
