import { NavigateFunction } from 'react-router-dom';
import { SOFTSWISS_FRAME_DOMAIN } from 'src/pages/SportSwiss/config';
import { extractInAppPathnameFromFrameLocation } from './extractInAppPathnameFromFrameLocation';

export const prepareFrameMessagesHandler =
  (navigate: NavigateFunction) => (e: MessageEvent<any>) => {
    if (e.origin !== SOFTSWISS_FRAME_DOMAIN || e.data?.type !== 'sportsbook:page-loaded') return;

    const inFrameLocation = e.data.payload.url;
    const inAppPathname = extractInAppPathnameFromFrameLocation(inFrameLocation);

    navigate(`/sport/${inAppPathname || 'main-page'}`);
  };
