import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow, Info } from 'src/components/svg';
import { prepareBonuses } from 'src/utils';
import './DepositBonuses.css';
import { NavLink } from 'react-router-dom';
import {
  Bonus,
  useCurrency,
  useDepositsCount,
  useGetBonuses,
  makeFloat,
  useScrollIntoView
} from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { DepositBonusesIcon } from '../../../../components/svg/DepositBonusesIcon';

interface DepositBonusesProps {
  handleSelectBonus: (bonus: Bonus | null) => void;
  selectedBonus: Bonus | null;
  isModal: boolean;
}

export const DepositBonuses = ({
  handleSelectBonus,
  selectedBonus,
  isModal
}: DepositBonusesProps) => {
  const { t } = useTranslation();
  const { closeModal, openedModalName } = useModalContext();
  const { data: rawBonuses } = useGetBonuses();
  const [showDepositBonuses, setShowDepositBonuses] = useState(false);
  const { currencyIcon, currencyName } = useCurrency();
  const depositsCount = useDepositsCount();

  const bonuses = useMemo(
    () => prepareBonuses(rawBonuses, depositsCount),
    [depositsCount, rawBonuses]
  );

  const bonusesListRef = useScrollIntoView({
    condition: showDepositBonuses && isModal,
    effectDependencies: [showDepositBonuses, isModal],
    scrollIntoViewParams: { behavior: 'smooth' }
  });

  const changeShowDepositBonuses = () => {
    setShowDepositBonuses((showDepositBonuses) => !showDepositBonuses);
  };

  if (bonuses.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex items-center gap-2.5 cursor-pointer" onClick={changeShowDepositBonuses}>
        <DepositBonusesIcon />
        <p className="font-bold text-base leading-[110%] text-[#FFFFFF]">{t('depositTab.Bonus')}</p>
        <Arrow
          className={classNames('w-[20px] stroke-[white]', showDepositBonuses ? 'rotate-180' : '')}
        />
      </div>
      {showDepositBonuses && (
        <div className="w-full overflow-auto">
          <div className="depositBonusCardsContainer" ref={bonusesListRef}>
            {bonuses.map((bonus: Bonus) => (
              <div
                key={bonus.id}
                onClick={() => {
                  handleSelectBonus(selectedBonus?.id === bonus.id ? null : bonus);
                }}
                className={classNames(
                  'cursor-pointer',
                  bonus.id === selectedBonus?.id ? 'depositBonusCard' : 'depositBonusCardInactive'
                )}>
                <div className="select-none">
                  <p>
                    <strong>
                      {bonus.percent}% {bonus.freeSpins ? `+ ${bonus.freeSpins} FS` : null}
                    </strong>
                  </p>
                  <p>
                    {t('accountWalletDeposite.upTo')} {currencyIcon}
                    {makeFloat(bonus.maxPayout[currencyName], 0)}
                  </p>
                  <p>
                    {t('accountWalletDeposite.forOneDepositFrom')} {currencyIcon}
                    {makeFloat(bonus.minDepositAmount[currencyName], 0)}
                  </p>
                </div>
                {openedModalName === 'POPUP_DEPOSIT' || (
                  <NavLink
                    aria-label={`${bonus.name}`}
                    to={`/bonuses/${bonus.id}`}
                    onClick={() => {
                      closeModal();
                    }}>
                    <Info fill={bonus.id === selectedBonus?.id ? 'black' : 'white'} />
                  </NavLink>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
