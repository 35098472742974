import { useGetAlternativeAuthMethods } from 'react-easyrocket';
import { SocialMediaLink } from './components/SocialMediaLink';
import { Skeletons } from '../Skeletons';
import { DetailedHTMLProps, FC, HTMLAttributes, MouseEventHandler } from 'react';
import { twJoin } from 'tailwind-merge';

interface AlternativeAuthMethodsBlockProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onLinkClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
}

export const AlternativeAuthMethodsBlock: FC<AlternativeAuthMethodsBlockProps> = ({
  className,
  onLinkClick
}) => {
  const { alternativeAuthMethods, isLoading } = useGetAlternativeAuthMethods();
  const shouldCollapseLinks = alternativeAuthMethods.filter((item) => item.is_enabled).length > 2;

  if (!isLoading && alternativeAuthMethods.every((item) => !item.is_enabled)) {
    return null;
  }

  return (
    <div className={twJoin('flex flex-col justify-center items-center w-full gap-2.5', className)}>
      {isLoading ? (
        <>
          <div className="w-full flex justify-center items-center gap-4">
            <Skeletons
              count={2}
              className="flex text-base font-normal text-white rounded-[35px] justify-start h-10 p-2 items-center w-full border"
            />
          </div>
        </>
      ) : (
        <>
          <div
            className={twJoin(
              'w-full flex justify-center items-center gap-4',
              shouldCollapseLinks ? 'gap-2' : 'gap-4'
            )}>
            {alternativeAuthMethods.map((method) => {
              if (!method.is_enabled) return null;

              return (
                <div key={method.name} className={twJoin(!shouldCollapseLinks && 'w-full')}>
                  {method.is_enabled && (
                    <SocialMediaLink
                      onLinkClick={onLinkClick}
                      name={method.name}
                      path={method.oauth_url}
                      isCollapsed={shouldCollapseLinks}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className="flex w-full text-active-radio gap-2 items-center justify-between">
        <hr className="text-active-radio w-full" />
        <span className="text-sm">or</span>
        <hr className="text-active-radio w-full" />
      </div>
    </div>
  );
};
