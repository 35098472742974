import { RefObject, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { prepareFrameMessagesHandler } from './utils/prepareFrameMessagesHandler';
import { changeInFrameLocation } from './utils/changeInFrameLocation';

type UseLinkOpenerParams = {
  sportFrameRef: RefObject<HTMLIFrameElement>;
};

export const useSynchronizePaths = ({ sportFrameRef }: UseLinkOpenerParams) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleFrameMessage = prepareFrameMessagesHandler(navigate);

    window.addEventListener('message', handleFrameMessage);
    return () => {
      window.removeEventListener('message', handleFrameMessage);
    };
  }, []);

  useEffect(() => {
    const frameContentWindow = sportFrameRef.current?.contentWindow;

    changeInFrameLocation(frameContentWindow, pathname);
  }, [pathname]);
};
