import { FormEvent } from 'react';
import { FullInfoResponse, PaymentFormField } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import './style.css';
import classNames from 'classnames';

interface PhoneInputProps {
  field: PaymentFormField;
  shouldFetchDefalutValue: (field: PaymentFormField, fullInfo: FullInfoResponse) => string;
  fullInfo: FullInfoResponse;
  labelWithPadding: boolean;
}

export const PhoneInput = ({
  field,
  shouldFetchDefalutValue,
  fullInfo,
  labelWithPadding
}: PhoneInputProps) => {
  const { t } = useTranslation();
  const handlePhoneValidation = (event: FormEvent<HTMLInputElement>) =>
    (event.currentTarget.value = event.currentTarget.value.replace(/[^\d]/g, ''));

  return (
    <div className="flex w-full flex-col items-start justify-between gap-2" key={field.name}>
      {field.title ? (
        <label className={classNames('text-base', labelWithPadding && 'pl-5')}>{field.title}</label>
      ) : null}
      <div className="phone-input-container self-stretch">
        <input
          className="phone-input"
          type="tel"
          name={field.name}
          placeholder={field.title}
          required={field.toShow}
          defaultValue={shouldFetchDefalutValue(field, fullInfo)}
          pattern="^[^+0].*"
          onInput={handlePhoneValidation}
        />
      </div>
      <span className="phone-input-error">{t('apiErrors.invalidPhone')}</span>
    </div>
  );
};
