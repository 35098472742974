import { CONTENT_URL } from 'src/api';
import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from 'src/hooks/useModalContext';
import { useCallback } from 'react';
import { ProviderTournament } from 'react-easyrocket';
import ModalWrapper from '../ModalWrapper';

export const NewTournament = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modalProps, closeModal } = useModalContext<ProviderTournament>();

  const handleShowMoreClick = useCallback(() => {
    navigate(`/tournaments/${modalProps.code}`, { state: { id: modalProps.code } });
    closeModal();
  }, [closeModal, modalProps.code, navigate]);

  return (
    <ModalWrapper
      isTransparentBg
      containerClassName="overflow-visible"
      className="overflow-visible">
      <div className="max-w-[400px]">
        <img
          src={`${CONTENT_URL}${modalProps.imageMobile}`}
          className="z-0 relative translate-y-[5%]"
          alt="new tournament image"
        />
        <div className="p-[20px] bg-text-secondary z-[1] relative">
          <p className="text-center text-[#AD31E0] text-[20px] font-bold mb-[15px]">
            {modalProps?.title}
          </p>
          <p className="text-center text-[#1F1F1F] text-[16px] font-bold mb-[15px]">
            {modalProps?.description}
          </p>
          <Button
            title={t('general.participate') as string}
            label={t('general.participate') as string}
            mode="button-secondary"
            className="!w-full !max-w-[400px] mb-[15px]"
            onClick={handleShowMoreClick}
          />
          <div className="flex gap-3">
            <Button
              title={t('general.cancel') as string}
              label={t('general.cancel') as string}
              mode="button-transparent"
              className="m-auto"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              title={t('general.readMore') as string}
              label={t('general.readMore') as string}
              mode="button-primary"
              className="m-auto"
              onClick={handleShowMoreClick}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
