import classNames from 'classnames';
import { memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SidebarLink } from '../SidebarGamesDropdown/config';
import { useDrawer } from 'src/hooks/useDrawer';
import { useWindowSize } from 'react-easyrocket';

interface SidebarLinkProps {
  option: SidebarLink;
  count?: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const SidebarButton = ({ option: { icon, title, path }, count, onClick }: SidebarLinkProps) => {
  const { t } = useTranslation();
  const { closeLeft } = useDrawer();
  const { is1280 } = useWindowSize();

  if (onClick) {
    return (
      <button
        onClick={(e) => {
          onClick(e);
          !is1280 && closeLeft();
        }}
        className="w-full flex items-center gap-[8px] text-[18px] font-bold relative 768:hover:text-yellow 768:[&_>svg>path]:hover:fill-yellow">
        {icon}
        {t(title)}

        {Boolean(count) && (
          <p className="bg-yellow flex flex-col items-center justify-center rounded-full text-[8px] font-bold w-[16px] h-[16px] text-text-primary">
            {count}
          </p>
        )}
      </button>
    );
  }

  return (
    <NavLink
      className={({ isActive }) =>
        classNames(
          'w-full flex items-center gap-[8px] text-[18px] font-bold relative 768:hover:text-yellow 768:[&_>svg>path]:hover:fill-yellow',
          isActive && 'text-yellow [&>svg>path]:fill-yellow'
        )
      }
      to={path}
      onClick={() => !is1280 && closeLeft()}>
      {icon}
      {t(title)}

      {Boolean(count) && (
        <p className="bg-yellow flex flex-col items-center justify-center rounded-full text-[8px] font-bold w-[16px] h-[16px] text-text-primary">
          {count}
        </p>
      )}
    </NavLink>
  );
};

export default memo(SidebarButton);
