import {
  allCountriesData,
  COUNTRIES_CURRENCIES_MAP,
  IconCurrency,
  jackpotBadgeUtilObject,
  sortType
} from '../constants';
import allCountries from '../constants/countries.v1.json';
import {
  BONUS_TRANSACTION_TYPE,
  Country,
  DropDownOption,
  PaymentMethod,
  PaymentMethodOption
} from '../types';
import { GetDataFromLS } from 'src/components/Modal/JackpotWinModal/types';
import {
  Bonus,
  BonusesResponse,
  CompletedJackpot,
  CompletedJackpotsResponse,
  Jackpot
} from 'react-easyrocket';
import dayjs from 'dayjs';
import { SOCIAL_MEDIA_ICONS } from 'src/constants/socialMediaIcons';

export const getCurrencyIcon = (currency?: string) => {
  if (!currency) {
    return '€';
  }
  return IconCurrency[currency as keyof typeof IconCurrency];
};

export const getPhoneCodeByCountryCode = (countryCode: string) => {
  const countyInfo = allCountriesData.find((country) => country.alpha3 === countryCode);
  return countyInfo?.phoneCodes[0];
};

export const findCountryCodeByName = (name: string | undefined) => {
  if (!name) return undefined;
  return allCountries.find((c) => c.name === name)?.alpha3;
};

export const findCountryNameByCode = (code: string) =>
  allCountries.find((c) => c.alpha3 === code)?.name;

export const getArrayOfCountryPhoneCodes = (countries: Country[]) => {
  const uniqueCodes = new Set<string>();
  const resultMap: { [name: string]: string } = {};

  countries.forEach((item) => {
    const code = item.phoneCodes[0];
    if (code) {
      uniqueCodes.add(code);
      resultMap[code] = item.alpha2;
    }
  });

  const result = Array.from(uniqueCodes).map((code) => ({ id: resultMap[code], name: code }));

  return result.sort((a, b) => a.name.localeCompare(b.name));
};

// !DEPRECATED FN => dont use this
export const currencyIcon = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'RUB':
      return '₽';
    case 'FS':
      return 'FS';
    case 'JPY':
      return '¥';
    default:
      return '€';
  }
};

export const prepareVoucherOptions = (options?: PaymentMethodOption[]): DropDownOption[] => {
  return (options || [])
    .sort((currentOption, nextOption) => (currentOption.id || 0) - (nextOption.id || 0))
    .map((el) => ({
      id: el.id?.toString() || '',
      name: `${el.amount} ${el.currency}`
    }));
};

export const validateDepositButton = (
  isFormValid: boolean,
  coin: string,
  paymentMethod?: PaymentMethod
) => {
  if (paymentMethod?.options?.length && paymentMethod?.type !== 'voucher') {
    return !isFormValid || !coin || !paymentMethod;
  }
  return !isFormValid || !paymentMethod;
};

export const setBonusTransactionTypeName = (typeNumber: number) => {
  if (typeNumber in BONUS_TRANSACTION_TYPE) {
    return BONUS_TRANSACTION_TYPE[typeNumber];
  }
  return '';
};

export const findSortById = (id: string) => sortType.find((c) => c.id === id);

export const getLevelInPercents = (pointsToReach?: string | number, userPoints?: number) => {
  if (pointsToReach === undefined || userPoints === undefined || userPoints === 0) return '0%';
  if (pointsToReach === '\u221e') return '100%';
  if (typeof pointsToReach === 'string') {
    return `${(userPoints / parseInt(pointsToReach, 10)) * 100}%`;
  }
  if (typeof pointsToReach === 'number') {
    return `${((userPoints / pointsToReach) * 100).toFixed(2)}%`;
  }
};

export const getLevelInNumber = (pointsToReach?: string | number, userPoints?: number) => {
  if (pointsToReach === undefined || userPoints === undefined || userPoints === 0) return 0;
  if (pointsToReach === '\u221e') return 100;
  if (typeof pointsToReach === 'string') {
    return (userPoints / parseInt(pointsToReach, 10)) * 100;
  }
  if (typeof pointsToReach === 'number') {
    return (userPoints / pointsToReach) * 100;
  }
};

const JACKPOTS_ORDER = ['jackpot-grand', 'jackpot-middle', 'jackpot-mini'];
export const prepareJackpotsForBanner = (jackpots: Jackpot[]) => {
  const result: Jackpot[] = [];
  JACKPOTS_ORDER.forEach((code) => {
    const j = jackpots.find((jackpot) => jackpot.code === code);
    if (j) result.push(j);
  });
  return result;
};

export const prepareMethodOptions = (paymentMethod: PaymentMethod) => {
  if (!paymentMethod) return undefined;
  if (paymentMethod.options) {
    return paymentMethod.options.map((el) => ({
      id: el.id ? el.id.toString() : el.title,
      name: el.title
    }));
  }
  return undefined;
};

export const getBonusesByWeekDay = (bonuses: Bonus[]) => {
  const sortedBonuses = [];
  const dayOfWeek = dayjs().day();
  for (let i = 0; i < bonuses.length; i++) {
    if (bonuses[i].activeDayOfWeek.length === 0 || bonuses[i].activeDayOfWeek.includes(dayOfWeek)) {
      sortedBonuses.push(bonuses[i]);
    }
  }

  return sortedBonuses;
};

export const prepareBonuses = (bonuses: BonusesResponse, depositsCount?: number) => {
  if (depositsCount == undefined) return [];

  const filteredBonuses = bonuses
    .filter((bonus: Bonus) => bonus.tag)
    .filter((bonus: Bonus) => bonus.type === 5);
  const partnerBonuses = bonuses.filter((bonus: Bonus) => bonus.tag === 'partner');
  const sortedByThreeTagsArray = [];

  for (let i = 0; i < filteredBonuses.length; i++) {
    if (filteredBonuses[i].tag === 'standard') {
      sortedByThreeTagsArray.push(filteredBonuses[i]);
    }
    if (filteredBonuses[i].tag === 'vip') {
      const standardIndex = sortedByThreeTagsArray.findIndex((el) => el.tag === 'standard');
      if (standardIndex === -1) {
        sortedByThreeTagsArray.push(filteredBonuses[i]);
      } else {
        sortedByThreeTagsArray.splice(standardIndex, 0, filteredBonuses[i]);
      }
    }
    if (filteredBonuses[i].tag === 'special') {
      sortedByThreeTagsArray.unshift(filteredBonuses[i]);
    }
  }

  const sortedByTagsArray = partnerBonuses.concat(sortedByThreeTagsArray);

  const sortedAndFilteredBonuses = sortedByTagsArray.filter(
    (bonus) =>
      depositsCount + 1 >= bonus.numberDeposits.from &&
      (depositsCount + 1 <= bonus.numberDeposits.to || bonus.numberDeposits.to == 0)
  );

  return getBonusesByWeekDay(sortedAndFilteredBonuses).slice(0, 3);
};

export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const getLevelsString = (from?: number, to?: number): string => {
  if (from === undefined || to === undefined) {
    return '';
  }
  return `${from} exp. to Level ${to}`;
};

export const upToTop = ({
  behaviorSmooth,
  pageRef
}: {
  behaviorSmooth?: boolean;
  pageRef: any;
}) => {
  if (pageRef.current) {
    behaviorSmooth
      ? pageRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
      : pageRef.current?.scrollTo({ top: 0 });
  } else {
    behaviorSmooth ? window.scrollTo({ top: 0, behavior: 'smooth' }) : window.scrollTo({ top: 0 });
  }
};

export const getLatestJackpotByTitle = (
  title: string | undefined,
  jackpots: CompletedJackpotsResponse | undefined
) => {
  if (!jackpots || !jackpots.length || !title) {
    return undefined;
  }
  let latestJackpot: CompletedJackpot | undefined = undefined;
  for (let i = 0; i < jackpots.length; i++) {
    if (latestJackpot === undefined && jackpots[i].groupName === title) {
      latestJackpot = jackpots[i];
    }
    if (
      latestJackpot &&
      jackpots[i].dateComplete > latestJackpot.dateComplete &&
      jackpots[i].groupName === title
    ) {
      latestJackpot = jackpots[i];
    }
  }
  return latestJackpot;
};

export const executeAdditionalGTMScript = (userId: number | undefined) => {
  (function () {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'userId',
      user_id: userId ? userId.toString() : userId
    });
  })();
};

export const executeRegistrationGTMScript = (userId: number) => {
  executeAdditionalGTMScript(userId);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'registration',
    user_id: userId.toString()
  });
};

export const getCurrencyByCountryCode = (countryCode?: string) => {
  if (!countryCode) return 'USD';
  return COUNTRIES_CURRENCIES_MAP[countryCode] || 'USD';
};

type PrepareJackpotBadgeForGameCard = (
  jackpots: Jackpot[],
  queryParams: string
) =>
  | undefined
  | {
      currency: string;
      badgeColor: string;
      jackpotCode: string;
    };

const JACKTOP_BADGE_CURRENCIES = {
  EUR: true,
  USD: true,
  AUD: true
};

export const prepareJackpotBadgeForGameCard: PrepareJackpotBadgeForGameCard = (
  jackpots,
  queryParams
) => {
  const jackpotCodes = ['grand', 'middle', 'mini'];
  const queryParamsInLowercase = queryParams.toLowerCase();
  const jackpotCode = jackpotCodes.find((code) => queryParamsInLowercase.includes(code));

  if (!jackpotCode) {
    return undefined;
  }

  const jackpotToPrepare = jackpots.find((jackpot) => jackpot.code === `jackpot-${jackpotCode}`);
  if (!jackpotToPrepare || !jackpotToPrepare.jackpotInfo) {
    return undefined;
  }

  const currency = jackpotToPrepare.jackpotInfo.currency;
  if (!(currency in JACKTOP_BADGE_CURRENCIES)) {
    return undefined;
  }

  const jackpotToUse = jackpotBadgeUtilObject[jackpotToPrepare.code];
  const badgeColor = jackpotToUse.badgeColor;

  return {
    jackpotCode,
    currency: jackpotToPrepare.jackpotInfo.currency,
    badgeColor
  };
};

export const getRefetchIntervalForTransactionsFromJackpotsProbability = (jackpots: Jackpot[]) => {
  const probability: number = jackpots.reduce((currentProbability, jackpot) => {
    if (jackpot.jackpotInfo?.probability && jackpot.jackpotInfo.probability > currentProbability) {
      return jackpot.jackpotInfo.probability;
    }
    return currentProbability;
  }, 0);
  if (probability === 0) {
    return 30000;
  }
  if (probability > 90) {
    return 5000;
  }
  const interval = -281 * probability + 30281;
  return interval;
};

export const getDataFromLS: GetDataFromLS = (key, defaultValue) => {
  const completedNoWagerJackpotsJSON = localStorage.getItem(key);
  return completedNoWagerJackpotsJSON ? JSON.parse(completedNoWagerJackpotsJSON) : defaultValue;
};

export function getUserIdFromLS(): number | undefined {
  const userId = localStorage.getItem('userId');
  return userId ? parseInt(userId) : undefined;
}

export function getLanguageFromLS() {
  return localStorage.getItem('language');
}

export function gameNameToUrl(gameName?: string) {
  if (!gameName) return '';
  return gameName.replace(/[^A-Z0-9]+/gi, '-');
}

export function prepareGameUrl(gameId?: number, gameName?: string) {
  if (!gameId) return '';
  const gameNamePart = gameNameToUrl(gameName);
  return `/game/${gameId}` + (gameNamePart ? `-${gameNamePart}` : '');
}

export function getSocialMediaLinkIcon(name: string): string {
  switch (name) {
    case 'Google':
      return SOCIAL_MEDIA_ICONS.GOOGLE;
    case 'Microsoft':
      return SOCIAL_MEDIA_ICONS.MICROSOFT;
    default:
      return '';
  }
}
