import { FC } from 'react';
import { PaymentFormField } from 'react-easyrocket';

interface SelectComponentProps {
  field: PaymentFormField;
  options: { id: string; name: string }[];
  defaultValue?: string;
  className: string;
}

export const SelectComponent: FC<SelectComponentProps> = ({
  field,
  options,
  defaultValue,
  className
}) => {
  return (
    <select name={field.name} key={field.name} defaultValue={defaultValue} className={className}>
      {options.map((option) => (
        <option value={option.id} selected={option.id === field.value} key={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};
