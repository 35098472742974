import { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { passAffiliateParams } from 'react-easyrocket';
import { SOCIAL_ICONS } from '../config';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import classes from './styles.module.css';

interface SocialMediaLinkProps {
  name: string;
  path: string;
  isCollapsed?: boolean;
  onLinkClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
}

export const SocialMediaLink: FC<SocialMediaLinkProps> = ({
  name,
  path,
  isCollapsed,
  onLinkClick
}) => {
  const {
    i18n: { language }
  } = useTranslation();
  const affiliateParams = passAffiliateParams();

  const url = new URL(path);

  const params = new URLSearchParams(url.search);

  params.append('lang', language);
  Object.entries(affiliateParams).forEach(([key, value]) => {
    params.append(key, value);
  });

  const icon = SOCIAL_ICONS[name as keyof typeof SOCIAL_ICONS];

  return (
    <Link
      onClick={onLinkClick}
      referrerPolicy="origin"
      to={`${url.origin.toString()}${url.pathname.toString()}?${params.toString()}`}
      className={classes.link}>
      <img
        height={24}
        width={24}
        className={twJoin('h-6 w-6', !isCollapsed && 'mr-2.5')}
        src={icon || ''}
        alt={name}
      />
      {!isCollapsed && name}
    </Link>
  );
};
