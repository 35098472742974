type TotalJackpot = { [currency: string]: { [jackpotType: string]: { from: number; to: number } } };

export const totalJackpot: TotalJackpot = {
  ['SEK']: {
    total: { from: 5000, to: 565000 },
    grand: { from: 350000, to: 450000 },
    middle: { from: 70000, to: 100000 },
    mini: { from: 5000, to: 15000 }
  },
  ['INR']: {
    total: { from: 40000, to: 4_520_000 },
    grand: { from: 2_800_000, to: 3_600_000 },
    middle: { from: 560000, to: 800000 },
    mini: { from: 40000, to: 120000 }
  },
  ['BRL']: {
    total: { from: 2500, to: 283000 },
    grand: { from: 175000, to: 225000 },
    middle: { from: 35000, to: 50000 },
    mini: { from: 2500, to: 8000 }
  },
  ['DKK']: {
    total: { from: 3750, to: 421_000 },
    grand: { from: 260000, to: 335_000 },
    middle: { from: 53000, to: 75000 },
    mini: { from: 3750, to: 11000 }
  },
  ['NZD']: {
    total: { from: 750, to: 85_300 },
    grand: { from: 53000, to: 68000 },
    middle: { from: 11000, to: 15000 },
    mini: { from: 750, to: 2300 }
  },
  ['NOK']: {
    total: { from: 5000, to: 565_000 },
    grand: { from: 350000, to: 450_000 },
    middle: { from: 70000, to: 100000 },
    mini: { from: 5000, to: 15000 }
  },
  ['PLN']: {
    total: { from: 2500, to: 283_000 },
    grand: { from: 175000, to: 225000 },
    middle: { from: 35000, to: 50000 },
    mini: { from: 2500, to: 8000 }
  },
  ['CAD']: {
    total: { from: 750, to: 85_250 },
    grand: { from: 53000, to: 68000 },
    middle: { from: 10500, to: 15000 },
    mini: { from: 750, to: 2250 }
  },
  ['AUD']: {
    total: { from: 750, to: 85_250 },
    grand: { from: 53000, to: 68000 },
    middle: { from: 10500, to: 15000 },
    mini: { from: 750, to: 2250 }
  },
  ['CHF']: {
    total: { from: 500, to: 56_500 },
    grand: { from: 35000, to: 45_000 },
    middle: { from: 7000, to: 10_000 },
    mini: { from: 500, to: 1500 }
  },
  ['JPY']: {
    total: { from: 78000, to: 8_730_000 },
    grand: { from: 5_500_000, to: 7_000_000 },
    middle: { from: 1_000_000, to: 1_500_000 },
    mini: { from: 78000, to: 230_000 }
  },
  ['FS']: {
    total: { from: 1_125_000, to: 125_900_000 },
    grand: { from: 80_000_000, to: 100_000_000 },
    middle: { from: 1_600_000, to: 22_500_000 },
    mini: { from: 1_125_000, to: 3_400_000 }
  },
  ['RUB']: {
    total: { from: 50000, to: 5_650_000 },
    grand: { from: 3_500_000, to: 4_500_000 },
    middle: { from: 700_000, to: 1_000_000 },
    mini: { from: 50000, to: 150_000 }
  },
  ['USD']: {
    total: { from: 500, to: 56_500 },
    grand: { from: 35000, to: 45000 },
    middle: { from: 7000, to: 10000 },
    mini: { from: 500, to: 1500 }
  },
  ['EUR']: {
    total: { from: 500, to: 56_500 },
    grand: { from: 35000, to: 45000 },
    middle: { from: 7000, to: 10000 },
    mini: { from: 500, to: 1500 }
  },
  ['RON']: {
    total: { from: 2485, to: 283_000 },
    grand: { from: 17_395, to: 225_500 },
    middle: { from: 34_790, to: 50_000 },
    mini: { from: 2485, to: 7500 }
  }
};
