import { SupportedLanguages } from 'src/locale/types';

export const languageGroups: { [key in keyof SupportedLanguages]: string } = {
  en: '12',
  es: '196',
  au: '12',
  sl: '200',
  ro: '206',
  pt: '205',
  el: '201',
  fr: '198',
  de: '203'
};
