import dayjs from 'dayjs';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePickerLib from 'react-datepicker';

import { Button } from './components/Button';
import { useBoolean } from 'react-easyrocket';
import { PickerPosition } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import { twJoin } from 'tailwind-merge';

interface DatePickerProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onBlur?: () => void;
  pickerPosition?: PickerPosition;
  displayedValueFormat?: string;
  buttonClassName?: string;
  isFullScreenDesktop?: boolean;
}

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  placeholder,
  onBlur,
  pickerPosition = 'bottom',
  displayedValueFormat,
  buttonClassName,
  isFullScreenDesktop
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, , setClose, toogleOpen] = useBoolean(false);
  const isPositionBottom = pickerPosition === 'bottom';

  const handleButtonClick = () => {
    toogleOpen();
  };

  const handlePickerChange = (date: Date | null) => {
    toogleOpen();
    onChange(date ? dayjs(date).format('YYYY-MM-DD') : '');
  };

  const handleClear = () => {
    setClose();
    onChange('');
  };

  const handleToday = () => {
    setClose();
    onChange(dayjs(new Date()).format('YYYY-MM-DD'));
  };

  // close on click outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (
        containerRef.current?.contains(target) ||
        buttonRef.current?.contains(target) ||
        target.parentElement?.classList.contains('react-datepicker__year-dropdown') ||
        target.parentElement?.classList.contains('react-datepicker__month-dropdown')
      ) {
        return;
      }

      e.stopPropagation();
      setClose();
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [containerRef, buttonRef, setClose, isOpen]);

  return (
    <div className="relative">
      <Button
        value={value}
        placeholder={placeholder}
        onClick={handleButtonClick}
        ref={buttonRef}
        displayedValueFormat={displayedValueFormat}
        className={buttonClassName}
      />
      {isOpen && (
        <div
          className={twJoin(
            'fixed top-0 bottom-0 left-0 right-0 flex items-center z-[999] justify-center bg-i_date_fullscreen_bg',
            !isFullScreenDesktop &&
              '1024:absolute 1024:left-1/2 1024:-translate-x-1/2 1024:right-auto 1024:block',
            !isFullScreenDesktop &&
              (isPositionBottom
                ? '1024:-bottom-2 1024:translate-y-full 1024:top-auto'
                : '1024:-top-2 1024:-translate-y-full 1024:bottom-auto')
          )}>
          <div ref={containerRef}>
            <DatePickerLib
              selected={value !== '' ? new Date(value) : null}
              onBlur={onBlur}
              value={value}
              onChange={handlePickerChange}
              showMonthDropdown
              showYearDropdown
              inline>
              <div className="flex items-center justify-between clear-both">
                <button
                  className="p-1 border border-input-border border-solid"
                  onClick={handleClear}>
                  {t('general.clear')}
                </button>
                <button
                  className="p-1 border border-input-border border-solid"
                  onClick={handleToday}>
                  {t('general.today')}
                </button>
              </div>
            </DatePickerLib>
          </div>
        </div>
      )}
    </div>
  );
};
