import { CONTENT_URL } from 'src/api';
import { Button } from 'src/components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProviderTournament, useApiContext } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';

export const TournamentsCarouselCard = ({ tournament }: { tournament: ProviderTournament }) => {
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const { t } = useTranslation();
  return (
    <div
      className="aspect-[40/29] w-[100%] bg-cover bg-no-repeat flex flex-col items-center justify-end"
      style={{
        backgroundImage: `url(${CONTENT_URL}${tournament.imageMobile})`,
        backgroundSize: '100% 100%'
      }}>
      {isAuthenticated ? (
        <Link
          to={`/tournaments/${tournament.code}`}
          className="mb-[6%] min-w-[190px]"
          aria-label="tournament">
          <Button
            title={`${t('tournaments.participate')}`}
            mode="button-secondary"
            className="mt-2.5 text-sm"
            label={`${t('tournaments.participate')}`}
          />
        </Link>
      ) : (
        <Button
          title={`${t('tournaments.participate')}`}
          mode="button-secondary"
          onClick={() => openModal('SIGNUP')}
          className="min-w-[190px] mb-[6%]"
          label={`${t('tournaments.participate')}`}
        />
      )}
    </div>
  );
};
