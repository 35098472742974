import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Buttons/Button';
import { TournamentsCard } from './components/TournamentsCard';
import { Link } from 'react-router-dom';
import { useProviderTournamentNotification } from 'src/hooks/useProviderTournamentNotification';

export const BlockTournaments = () => {
  const tournaments = useProviderTournamentNotification();
  const { t } = useTranslation();

  if (tournaments.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 mt-11 px-5">
      <div className="flex justify-between items-center">
        <p className="text-bt_text text-xl font-bold leading-6">{t('menuItem.tournaments')}</p>
        <Link to="tournaments" aria-label="tournaments">
          <Button
            title={`${t('general.all')}`}
            mode="button-transparent"
            className="768:text-lg font-normal px-7 text-xs 768:max-w-[190px] 768:h-auto h-[30px] max-w-auto w-auto 768:w-full">
            {t('general.all')}
          </Button>
        </Link>
      </div>
      <div className="flex flex-col w-full h-full gap-y-6 self-center">
        {tournaments.map((tournament, index) => (
          <TournamentsCard key={index} tournament={tournament} />
        ))}
      </div>
    </div>
  );
};
