import { SmarticoButton } from 'src/components/SmarticoButton';
import { smarticoButtons } from '../../constants';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useState } from 'react';
import { Separator } from 'src/components/Separator/Separator';
import { useApiContext, useGetTransactions } from 'react-easyrocket';
import { SkeletonOfAnySize } from 'src/components/Skeletons';

export const SmarticoButtonsCollapsed = () => {
  const { isAuthenticated } = useApiContext();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const { arrayTransactions, isFetching } = useGetTransactions({
    type: ['Deposit', 'ChangeBalanceInc'],
    status: ['Complete', 'Rejected', 'PendingWager', 'WagerZeroing']
  });

  const shouldDepositFirst = !Boolean(arrayTransactions.length);

  if (isFetching && isAuthenticated)
    return (
      <>
        <Separator width="w-[60%] mb-5" noMarginTop />

        <SkeletonOfAnySize className="w-full h-[56px]" />
      </>
    );

  return (
    <>
      <Separator width="w-[60%] mb-5" noMarginTop />
      <div
        className="smartico-btn-collapsed"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}>
        <SmarticoButton
          isCollapsed
          shouldDepositFirst={shouldDepositFirst}
          label={t(smarticoButtons[0].label)}
          icon={smarticoButtons[0].icon}
          className="rounded-lg"
        />
        <Tooltip
          anchorSelect=".smartico-btn-collapsed"
          place="right"
          noArrow
          isOpen={isOpen}
          opacity={1}
          className="!p-0 group-hover:block !pointer-events-auto -translate-x-[66px] rounded-lg overflow-hidden">
          <div className="flex">
            {smarticoButtons.map(({ key, label, icon, isSoonLabel, action, navigate }) => (
              <SmarticoButton
                key={key}
                shouldDepositFirst={shouldDepositFirst}
                label={t(label)}
                icon={icon}
                action={action}
                isCollapsed={true}
                navigate={navigate}
                isSoonLabel={isSoonLabel}
              />
            ))}
          </div>
        </Tooltip>
      </div>
    </>
  );
};
