import { useHref, useLocation, useParams } from 'react-router-dom';
import { useNavigateLng } from './useNavigateLng';

export const useSetLanguageToUrl = () => {
  const { lang } = useParams();
  const location = useLocation();
  const href = useHref(location);
  const navigate = useNavigateLng();

  return () => {
    if (!lang) {
      navigate(href);
    } else {
      const pathNameWithoutLang = href.replace(`/${lang}`, '');

      navigate(pathNameWithoutLang);
    }
  };
};
