import { CardPaymentMethod } from './PaymentMethod';
import { PaymentMethod } from 'src/types';
import { Skeletons } from 'src/components/Skeletons';

export const PaymentMethods = ({
  onSelectPaymentMethod,
  selectedMethod,
  methods = []
}: {
  onSelectPaymentMethod?: (method: PaymentMethod) => void;
  selectedMethod?: number;
  methods: PaymentMethod[];
}) => {
  return (
    <div className="paymentsMethods flex flex-wrap justify-center gap-[4px]">
      {methods?.length ? (
        methods.map((method, index) => (
          <CardPaymentMethod
            key={method._reactDepositMethodId}
            method={method}
            onClick={onSelectPaymentMethod}
            selected={method._reactDepositMethodId === selectedMethod}
            id={`${index}`}
          />
        ))
      ) : (
        <Skeletons className="w-[75px] 768:w-[85px] h-[80px]" count={12} />
      )}
    </div>
  );
};
