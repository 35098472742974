import { t } from 'i18next';
import { useGetActiveUserTournaments, useGetShortBalance } from 'react-easyrocket';
import { useNavigate } from 'react-router-dom';
import { CONTENT_URL } from 'src/api';

import { memo } from 'react';
import { useGetNewestTournament } from '../hooks/useGetNewestTournament';
import { PrizePool } from 'src/components/TournamentComponent/PrizePool';
import { Button } from 'src/components/Buttons/Button';
import { TournamentTimer } from 'src/components/TournamentComponent/TournamentTimer';

const TournamentSlide = () => {
  const { data: activeUserTournaments } = useGetActiveUserTournaments();
  const newestTournament = useGetNewestTournament(true);

  const navigate = useNavigate();
  const { data: userBalance } = useGetShortBalance();

  const isAlreadyParticipate = activeUserTournaments?.some(
    (partipicateTournament) => newestTournament?.id === partipicateTournament.id
  );

  const handleMore = () => {
    navigate(`/tournaments/local/${newestTournament?.id}`);
  };

  return (
    <div className="relative z-[1] flex h-[407px] w-[clamp(96%,98%,374px)] max-w-[374px] flex-col items-center justify-start overflow-hidden rounded-[10px] bg-cover bg-no-repeat">
      <div className="absolute z-[2] h-full w-full bg-slt_bg">
        <img
          className="object-cover object-[-500px] h-full w-full brightness-50"
          src={CONTENT_URL + newestTournament?.imageMobile}
          alt="tournament"
        />
      </div>
      <div className="relative z-[3] flex flex-col items-center justify-start h-full px-[15px] py-[20px]">
        {isAlreadyParticipate && (
          <p className="text-slt_participate_text text-[12px] leading-[13px] py-[8px] px-[6px] bg-slt_participate_bg rounded-[7px] font-bold mb-[20px]">
            {t('tournaments.alreadyParticipate')}
          </p>
        )}
        <h3 className="text-slt_newest_toutnament_name font-black text-[32px] leading-[35px] tracking-[0.03em] text-center mb-[20px]">
          {newestTournament?.name}
        </h3>
        <PrizePool newestTournament={newestTournament} userBalance={userBalance} />
        <TournamentTimer newestTournament={newestTournament} />
        <Button
          className="btn py-[10px] px-[40px] mt-auto leading-[19px] !w-[190]"
          onClick={handleMore}
          mode="button-secondary">
          {isAlreadyParticipate ? t('general.more') : t('general.startWinning')}
        </Button>
      </div>
    </div>
  );
};

// !text-[black]
export default memo(TournamentSlide);
