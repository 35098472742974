import { createSearchParams, useNavigate } from 'react-router-dom';
import providerDefault from '../../assets/images/providerDefault.svg';
import { Provider, STORAGE_URL } from 'react-easyrocket';

export const ProviderButton = ({
  provider,
  onClick
}: {
  provider: Provider;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({
      pathname: '/games',
      search: createSearchParams({
        providerId: provider.id.toString()
      }).toString()
    });
  };

  return (
    <div
      onClick={() => {
        handleClick();
        onClick?.();
      }}
      className="flex flex-row items-center justify-between px-[5px] rounded-md w-full min-h-[34px] h-full cursor-pointer gap-2 bg-background-main hover:bg-button-secondary transition">
      <div className="flex items-center gap-2">
        <img
          src={`${STORAGE_URL}${provider.providerIcon}`}
          className="h-[14px] w-[16px] object-contain"
          alt={provider.name}
          onError={(event) => {
            const element = event.target as HTMLImageElement;
            element.src = providerDefault;
            element.onerror = null;
          }}
        />
        <p className="text-text-secondary text-[15px] font-medium break-all">{provider.name}</p>
      </div>
      <p className="text-yellow text-[14px] font-bold">{provider.gamesCount}</p>
    </div>
  );
};
