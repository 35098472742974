import { FC, InputHTMLAttributes } from 'react';
import { Input } from 'src/components/Inputs/Input';
import { PaymentFormField } from 'react-easyrocket';

interface InputComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  field: PaymentFormField;
  defaultValue: string;
  title?: string;
}

export const InputComponent: FC<InputComponentProps> = ({
  field,
  defaultValue,
  title,
  ...props
}) => {
  return (
    <Input
      {...props}
      required={field.toShow}
      label={title ? title : field.title}
      name={field.name}
      defaultValue={defaultValue}
      labelClassname="!text-[black]"
      placeholder={title ? title : field.title}
      overrideContainerClassName={field.toShow ? '' : 'hidden'}
      labelWithPadding={true}
      autoComplete="new-password"
    />
  );
};
