import { createSearchParams, useNavigate } from 'react-router-dom';
import providerDefault from 'src/assets/images/providerDefault.svg';
import { Provider, STORAGE_URL } from 'react-easyrocket';
import { FC } from 'react';
import { useDrawer } from 'src/hooks/useDrawer';

interface ProviderButtonProps {
  provider: Provider;
}

export const ProviderButton: FC<ProviderButtonProps> = ({ provider }) => {
  const navigate = useNavigate();
  const { closeProvidersSearch, closeIsTopSearchDrawerOpen } = useDrawer();

  const handleClick = () => {
    navigate({
      pathname: '/games',
      search: createSearchParams({
        providerId: provider.id.toString()
      }).toString()
    });

    closeProvidersSearch();
    closeIsTopSearchDrawerOpen();
  };

  return (
    <button
      onClick={handleClick}
      className="flex pl-[5px] rounded-[5px] pr-2 text-sm font-medium text-text-secondary text-left bg-background-hexademical py-1.5">
      <span className="h-full w-9 flex-none flex items-center justify-center">
        <img
          src={`${STORAGE_URL}${provider.providerIcon}`}
          className="h-[22px] w-[26px] object-contain"
          alt={provider.name}
          onError={(event) => {
            const element = event.target as HTMLImageElement;
            element.src = providerDefault;
            element.onerror = null;
          }}
        />
      </span>
      <span className="w-full whitespace-nowrap text-ellipsis overflow-hidden">
        {provider.name}
      </span>
    </button>
  );
};
