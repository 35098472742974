import { useTranslation } from 'react-i18next';
import { NavLink, createPath, useLocation } from 'react-router-dom';
import { SidebarContainerItemProps } from './SidebarContainerItem.types';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDrawer } from 'src/hooks/useDrawer';

export const SidebarContainerItem: FC<SidebarContainerItemProps> = ({
  icon,
  title,
  path,
  state,
  ...rest
}) => {
  const { t } = useTranslation();
  const { closeLeft } = useDrawer();
  const { pathname } = useLocation();

  return (
    <NavLink
      aria-label={`${t(title)}`}
      state={{ needScroll: state ? state : false }}
      to={createPath({
        pathname: path
      })}
      className={({ isActive }) =>
        twMerge(
          'w-full hover:text-ptc_text_hover text-ptc_text',
          isActive && pathname === path && 'text-ptc_text_hover'
        )
      }
      onClick={closeLeft}>
      {({ isActive }) => (
        <div
          className="w-full flex items-center gap-[10px] py-[6px] px-[12px] rounded-[10px] text-[14px] font-semibold bg-ptc_bg hover:scale-[0.98] transition-all group"
          {...rest}>
          <div
            className={twMerge(
              '[&>svg]:w-[24px] [&>svg]:h-[24px] group-hover:[&_path]:fill-ptc_text_hover',
              isActive && '[&>svg>path]:fill-ptc_text_hover'
            )}>
            {icon}
          </div>
          <p className="first-letter:uppercase">{t(title)}</p>
        </div>
      )}
    </NavLink>
  );
};
