import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, createPath, useLocation } from 'react-router-dom';
import { useGetJackpots } from 'react-easyrocket';
import { twJoin } from 'tailwind-merge';
import { FIFTEEN_SECONDS } from 'src/constants/time';
import { getCategories } from './utils/getCategories';
import { scrollToCenter } from './utils/scrollToCenter';

interface GamesCategoriesSwitcherProps {
  lightActiveLink?: boolean;
  onCategoryClick?: () => void;
}

export const GamesCategoriesSwitcher: FC<GamesCategoriesSwitcherProps> = ({
  lightActiveLink = true,
  onCategoryClick
}) => {
  const { t } = useTranslation();
  const { jackpots } = useGetJackpots(
    {},
    { refetchInterval: FIFTEEN_SECONDS, staleTime: FIFTEEN_SECONDS, cacheTime: FIFTEEN_SECONDS }
  );
  const location = useLocation();
  const scrollableRef = useRef<HTMLDivElement>(null);

  const categories = useMemo(
    () => getCategories(jackpots, location.pathname),
    [jackpots, location.pathname]
  );

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    const currentElement = document.getElementById(location.pathname);
    if (scrollableElement) {
      scrollToCenter(currentElement, scrollableElement);
    }
  }, [location]);

  return (
    <div
      className="switcher-wrapper py-4 flex justify-start overflow-x-scroll w-full gap-x-3 bounce"
      ref={scrollableRef}>
      {categories.map(({ path, title, image }, i) => (
        <div key={i} className="flex 768:gap-5 items-center gap-2" id={path}>
          <NavLink
            aria-label={`${path}`}
            to={createPath({
              pathname: path
            })}
            key={title}
            className={({ isActive }) =>
              twJoin(
                'w-full min-w-[60px] 768:py-1 px-2.5 first:pl-0 text-[16px] font-[900] lowercase justify-center items-center flex gap-1 flex-col text-gcs_text 768:rounded-[100px] hover:text-gcs_text_hover rounded-[15px] whitespace-nowrap h-full py-1 text-sm 768:text-[16px]',
                isActive &&
                  lightActiveLink &&
                  'text-gcs_text_active [&_svg_path]:fill-gcs_text_active',
                'transition-all duration-300 [&_svg_path]:transition-all [&_svg_path]:duration-300 [&_svg_path]:hover:fill-gcs_text_active'
              )
            }
            onClick={onCategoryClick}>
            <div className="w-[30px] h-[30px] flex justify-center items-center [&_svg]:w-full [&_svg]:h-auto">
              {image}
            </div>
            <span className="text-center mt-2 uppercase">{t(title)}</span>
          </NavLink>
        </div>
      ))}
    </div>
  );
};
