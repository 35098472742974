import { useApiContext } from 'react-easyrocket';
import { Navigate } from 'react-router-dom';

export const ProtectedAuthRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useApiContext();

  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};
