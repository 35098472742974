import classNames from 'classnames';

export function AmountPresets({
  onClick,
  value = '0',
  amountPresets = []
}: {
  value?: string;
  onClick?: (value: string) => void;
  amountPresets?: string[];
}) {
  return (
    <div className="flex justify-center gap-2">
      {amountPresets.map((preset) => {
        return (
          <button
            title={`${preset}`}
            key={preset}
            onClick={() => onClick?.(preset)}
            className={classNames(
              value.toString() === preset.toString()
                ? 'border-button-primary text-text-primary bg-button-primary'
                : 'border-text-secondary text-text-secondary bg-inherit',
              'border-[1px] border-solid rounded-[20px] py-[7px] w-[100px] text-[16px] font-medium jmid:py-[5px] jmid:font-bold jmid:leading-[100%]'
            )}>
            {preset.endsWith('000') ? `${preset.slice(0, preset.length - 3)}k` : preset}
          </button>
        );
      })}
    </div>
  );
}
