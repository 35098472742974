import React, { lazy } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useApiContext, useGetBlockedCountry } from 'react-easyrocket';

import Cookies from 'src/components/CookiesBanner';
import { SocialSignUpHandler } from 'src/components/SocialSignUpHandler';
import { BlockedCountryPlug } from 'src/components/BlockedCountryPlug/BlockedCountryPlug';
import { FreeSpinsHandler } from 'src/components/FreeSpinsHandler';
import { Layout } from 'src/components/Layout';
import { Modal } from 'src/components/Modal';
import { OpenSingnUpByLink } from 'src/components/OpenSingnUpByLink';
import { PageHead } from 'src/components/PageHead';
import { ProtectedAuthRoute } from 'src/components/ProtectedAuthRoute/ProtectedAuthRoute';
import { useFullScreen } from 'src/hooks/useFullScreen';
import { LandingWheelOfFortune } from 'src/pages/LandingWheelOfFortune';
import { LocalTournament } from 'src/pages/LocalTournament';
import { MobileLanding } from 'src/pages/MobileLanding';
import { SendAction } from 'src/components/SendAction';
import { ProgressiveTooltip } from 'src/components/ProgressiveTooltip/ProgressiveTooltip';
import { twMerge } from 'tailwind-merge';

import { Landing } from 'src/pages/Landing';
import { MainPage } from 'src/pages/Main';
import { ActivateBonus } from 'src/pages/ActivateBonus';
import { LiveChatWidget } from 'src/components/LiveChatWidget';
import { MessagesContainer } from 'src/modules/messages/components/MessagesContainer';
// import { SportSwissPage } from 'src/pages/SportSwiss';
import { SportSwissPage } from 'src/pages/SportSwiss';
import { DepositRedirectWrapper } from '../components/DepositRedirectWrapper';
import { SideEffects } from 'src/components/SideEffects';

const Bonus = lazy(() => import('src/pages/Bonus'));
const Bonuses = lazy(() => import('src/pages/Bonuses'));
const Jackpots = lazy(() => import('src/pages/Jackpots'));
const Tournaments = lazy(() => import('src/pages/Tournaments'));
const ContentPage = lazy(() => import('src/pages/ContentPage'));
const Faqs = lazy(() => import('src/pages/Faqs'));
const ProviderTournament = lazy(() => import('src/pages/ProviderTournament'));
const Games = lazy(() => import('src/pages/Games'));
const PwaPage = lazy(() => import('src/pages/PwaPage'));
const GameLayout = lazy(() => import('src/components/Layout/GameLayout'));
const GamePage = lazy(() => import('src/pages/Game'));
const AccountProfile = lazy(() => import('src/pages/AccountProfile'));
const AccountGameHistory = lazy(() => import('src/pages/AccountGameHistory'));
const AccountJackpots = lazy(() => import('src/pages/AccountJackpots'));
const AccountLayout = lazy(() => import('src/components/Layout/AccountLayout'));
const NewAccountVip = lazy(() => import('src/pages/NewAccountVip'));
const AccountWallet = lazy(() => import('src/pages/AccountWallet'));
const AccountPromo = lazy(() => import('src/pages/AccountPromo'));
const Vip = lazy(() => import('src/pages/Vip'));

// import { CatchGame } from 'src/pages/CatchGame';
// import { isShowCatchGame } from 'src/pages/CatchGame/helpers';
// import { EVENT_LINKS } from 'src/event/config';
// import EventPromoBanner from 'src/event/components/EventPromoBanner';
// import { isShowCardGame } from 'src/pages/CardGame/helpers';
// import CardGame from 'src/pages/CardGame';
// import ShopEvent from 'src/pages/ShopEvent';
// import ShopUnauthorizedEvent from 'src/pages/ShopEventUnauthorized';

export const Router = React.memo(() => {
  const { isAuthenticated } = useApiContext();
  const { refFullscreen } = useFullScreen();
  const { isBlockedCountry } = useGetBlockedCountry();
  const location = useLocation();
  const isLanding = location.pathname.includes('landing');

  // TODO раcкомментить после релиза
  // useNewsNotifications();
  if (isBlockedCountry) {
    return <BlockedCountryPlug />;
  }

  return (
    <>
      <main
        ref={refFullscreen}
        className={twMerge(
          'max-w-[2560px] m-auto w-full palette',
          isLanding && 'max-w-full min-h-full'
        )}
        id="Jacktop">
        <MessagesContainer />
        <Modal />
        <PageHead />
        <LiveChatWidget />
        <ProgressiveTooltip />
        {/* {shouldShowOktoberfestCategory && <EventNotification />}*/}
        <Routes>
          <Route
            path="/:lang?"
            element={
              <>
                <SideEffects />
                <Outlet />
              </>
            }>
            <Route
              path="account"
              element={
                <ProtectedAuthRoute>
                  <AccountLayout />
                </ProtectedAuthRoute>
              }>
              <Route index element={<Navigate to={'/account/profile/main'} />} />
              <Route path="profile/*" element={<AccountProfile />} />
              <Route path="transaction-history/*" element={<AccountGameHistory />} />
              <Route path="wallet/*" element={<AccountWallet />} />
              <Route path="jackpots" element={<AccountJackpots />} />
              <Route path="promo" element={<AccountPromo />} />
              <Route path="vip" element={<NewAccountVip />} />
            </Route>

            <Route element={<Layout />}>
              {/* SPORT START */}
              <Route path="sport/*" element={<SportSwissPage />} />
              {/* SPORT END */}
              <Route
                path="wheel-of-fortune"
                element={isAuthenticated ? <Navigate to={'/'} /> : <LandingWheelOfFortune />}
              />
              <Route index element={<MainPage />} />
              <Route path="auth" element={<SocialSignUpHandler />} />
              <Route path="games">
                <Route index path=":categoryId?" element={<Games />} />
              </Route>
              <Route path="jackpots" element={<Jackpots />} />
              {/* <Route path="halloween" element={<Halloween />} /> */}
              <Route path="tournaments" element={<Tournaments />} />

              <Route path="tournaments/:tournamentCode" element={<ProviderTournament />} />
              <Route path="tournaments/local/:tournamentId" element={<LocalTournament />} />
              <Route path="bonuses" element={<Bonuses />} />
              <Route path="bonuses/:bonus" element={<Bonus />} />
              <Route path="info_pages/:page" element={<ContentPage />} />
              <Route path="faq" element={<Faqs />} />
              <Route path="pwa-instruction/:deviceId?" element={<PwaPage />} />
              <Route path="vip" element={<Vip />} />
              {/* <Route*/}
              {/*  path="catch-the-beer"*/}
              {/*  element={shouldShowOktoberfestCategory ? <GameBeer /> : <Navigate to="/" />}*/}
              {/* />*/}
              {/* <Route
              path="shop"
              element={isAuthenticated ? <ShopEvent /> : <ShopUnauthorizedEvent />}
            />
            <Route
              path={EVENT_LINKS.CATCH_GAME}
              element={isShowCatchGame() ? <CatchGame /> : <Navigate to="/" />}
            />
            <Route
              path={EVENT_LINKS.CARD_GAME}
              element={isShowCardGame() ? <CardGame /> : <Navigate to="/" />}
            /> */}
            </Route>

            <Route path="game" element={<GameLayout />}>
              <Route index element={<Navigate to={'/games'} />} />
              <Route path=":gameIdAndName/:demo?" element={<GamePage />} />
            </Route>

            <Route
              path="deposit-success/:merchantId?"
              element={<DepositRedirectWrapper redirectType={'depositSuccess'} />}
            />
            <Route
              path="deposit-failure/*"
              element={<DepositRedirectWrapper redirectType={'depositFailure'} />}
            />
            <Route
              path="deposit-return/:merchantId?"
              element={<DepositRedirectWrapper redirectType={'depositReturn'} />}
            />
            <Route
              path="welcome-bonus"
              element={isAuthenticated ? <Navigate to={'/'} /> : <MobileLanding />}
            />
            <Route
              path="landing/welcome-bonus"
              element={isAuthenticated ? <Navigate to={'/'} /> : <Landing />}
            />
            <Route path="activate-bonus/:bonusId" element={<ActivateBonus />} />
            {/* TODO добавить 404 сюда */}
            <Route path="action" element={<SendAction />} />
            <Route path="*" element={<Navigate to={'/'} />} />
          </Route>
        </Routes>

        <Cookies />
        {/* <EventPromoBanner /> */}
        <FreeSpinsHandler />
        <OpenSingnUpByLink />
      </main>
    </>
  );
});

Router.displayName = 'Router';
