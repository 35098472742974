import {
  AllGamesIcon,
  AllLiveGamesIcon,
  CasinoIcon,
  JackpotIcon
} from 'src/components/svg/SidebarIcons';
import { AllLive } from 'src/components/svg/categories/AllLive';
import { BaccaratIcon } from 'src/components/svg/categories/Baccarat';
import { BlackjackIcon } from 'src/components/svg/categories/Blackjack';
import { BonusIcon } from 'src/components/svg/categories/Bonus';
import { BookIcon } from 'src/components/svg/categories/Book';
import { DropwinsIcon } from 'src/components/svg/categories/Dropwins';
import { FruitsIcon } from 'src/components/svg/categories/Fruits';
import { JackpotGrandIcon } from 'src/components/svg/categories/JackpotGrand';
import { JackpotMidIcon } from 'src/components/svg/categories/JackpotMid';
import { JackpotMiniIcon } from 'src/components/svg/categories/JackpotMini';
import { JackpotsIcon } from 'src/components/svg/categories/Jackpots';
import { LiveIcon } from 'src/components/svg/categories/Live';
import { Megaways } from 'src/components/svg/categories/Megaways';
import { NewIcon } from 'src/components/svg/categories/New';
import { PokerIcon } from 'src/components/svg/categories/Poker';
import { RouletteIcon } from 'src/components/svg/categories/Roulette';
import { Slots } from 'src/components/svg/categories/Slots';
import { TopIcon } from 'src/components/svg/categories/Top';
import { BlackjackLeague } from 'src/components/svg/categories/BlackjackLeague';
import { isBjLeagueVisible } from 'src/utils/bjLeague';
import { HalloweenIcon } from 'src/components/svg/categories/Halloween';
import { OktoberfestIcon } from 'src/components/svg/categories/Oktoberfest';
import { OlympicIcon } from 'src/components/svg/categories/OlympicEvent';
import { addHalloWeenCategory } from '../event/categories';
// import { ValentineIcon } from 'src/components/svg/categories/Valentine';
// import { EasterIcon } from 'src/components/svg/categories/Easter';

export type GameCategoryParams = {
  param: string; // название категории для бэкенда
  title: string; // локализованное название категории
  path: `/games/${GameCategoryName}` | '/games' | '/game/34642'; // URL путь к категории игр (34642 - Aviator)
  image: JSX.Element; // иконка категории игры,
  isEvent?: boolean; // оформление для ивента
};

export type GameCategoryName =
  | 'all'
  | 'all-live'
  | 'all-jackpots'
  | 'new'
  | 'jackpots'
  | 'top'
  | 'live'
  | 'slots'
  | 'megaways'
  | 'fruits'
  | 'books'
  | 'dropwins'
  | 'blackjack'
  | 'baccarat'
  | 'poker'
  | 'roulette'
  | 'bonus-games'
  | 'jackpot-mini'
  | 'jackpot-middle'
  | 'jackpot-grand'
  | 'blackjack-league'
  | 'halloween'
  | 'oktoberfest'
  | 'olympic_2024';
// | 'easter'
// | 'valentine';
// | 'olympic_2024'

export const GAME_CATEGORIES: Record<GameCategoryName, GameCategoryParams> = {
  all: {
    param: 'all',
    title: 'general.allGames',
    path: '/games',
    image: <AllGamesIcon />
  },
  'all-live': {
    param: 'all-live',
    title: 'general.allLiveGames',
    path: '/games/live',
    image: <AllLiveGamesIcon />
  },
  'all-jackpots': {
    param: 'all-jackpots',
    title: 'general.allJackpotGames',
    path: '/games/jackpots',
    image: <JackpotsIcon />
  },
  new: {
    param: 'new',
    title: 'categoriesItem.new',
    path: '/games/new',
    image: <NewIcon />
  },
  jackpots: {
    param: 'jackpots',
    title: 'categoriesItem.jackpots',
    path: '/games/jackpots',
    image: <JackpotsIcon />
  },
  top: {
    param: 'top',
    title: 'categoriesItem.top',
    path: '/games/top',
    image: <TopIcon />
  },
  live: {
    param: 'live',
    title: 'categoriesItem.live',
    path: '/games/live',
    image: <LiveIcon />
  },
  slots: {
    param: 'slots',
    title: 'categoriesItem.slots',
    path: '/games/slots',
    image: <Slots />
  },
  megaways: {
    param: 'megaways',
    title: 'categoriesItem.megaways',
    path: '/games/megaways',
    image: <Megaways />
  },
  fruits: {
    param: 'fruits',
    title: 'categoriesItem.fruits',
    path: '/games/fruits',
    image: <FruitsIcon />
  },
  books: {
    param: 'book',
    title: 'categoriesItem.bookSlots',
    path: '/games/books',
    image: <BookIcon />
  },
  dropwins: {
    param: 'dropwinscat',
    title: 'categoriesItem.dropWins',
    path: '/games/dropwins',
    image: <DropwinsIcon />
  },
  blackjack: {
    param: 'blackjack',
    title: 'categoriesItem.blackjack',
    path: '/games/blackjack',
    image: <BlackjackIcon />
  },
  baccarat: {
    param: 'baccarat',
    title: 'categoriesItem.baccarat',
    path: '/games/baccarat',
    image: <BaccaratIcon />
  },
  poker: {
    param: 'poker',
    title: 'categoriesItem.poker',
    path: '/games/poker',
    image: <PokerIcon />
  },
  roulette: {
    param: 'roulette',
    title: 'categoriesItem.roulette',
    path: '/games/roulette',
    image: <RouletteIcon />
  },
  'bonus-games': {
    param: 'my-bonus-games',
    title: 'gameCategories.bonusGames',
    path: '/games/bonus-games',
    image: <BonusIcon />
  },
  'jackpot-mini': {
    param: 'jackpot-mini',
    title: 'jackpots.mini',
    path: '/games/jackpot-mini',
    image: <JackpotMiniIcon />
  },
  'jackpot-middle': {
    param: 'jackpot-middle',
    title: 'jackpots.middle',
    path: '/games/jackpot-middle',
    image: <JackpotMidIcon />
  },
  'jackpot-grand': {
    param: 'jackpot-grand',
    title: 'jackpots.grand',
    path: '/games/jackpot-grand',
    image: <JackpotGrandIcon />
  },
  'blackjack-league': {
    param: 'bjleague',
    title: 'Bj League',
    path: '/games/blackjack-league',
    image: <BlackjackLeague />
  },

  halloween: {
    param: 'halloween',
    title: 'Halloween',
    path: '/games/halloween',
    image: <HalloweenIcon />,
    isEvent: true
  },
  oktoberfest: {
    param: 'oktoberfest',
    title: 'Oktoberfest',
    path: '/games/oktoberfest',
    image: <OktoberfestIcon />,
    isEvent: true
  },
  olympic_2024: {
    param: 'olympic_2024',
    title: 'Olympic 2024',
    path: '/games/olympic_2024',
    image: <OlympicIcon />
  }
  // easter: {
  //   param: 'easter',
  //   title: 'gameCategories.easter',
  //   path: '/games/easter',
  //   image: <EasterIcon />
  // },
  // end EURO_2024 event
  // valentine: {
  //   param: 'valentine',
  //   title: 'gameCategories.valentine',
  //   path: '/games/valentine',
  //   image: <ValentineIcon />
  // },
};

export const gameCategoriesMainList: GameCategoryParams[] = addHalloWeenCategory([
  // GAME_CATEGORIES.easter,
  GAME_CATEGORIES.top,
  GAME_CATEGORIES.jackpots,
  GAME_CATEGORIES.new,
  GAME_CATEGORIES.slots,
  GAME_CATEGORIES.megaways,
  GAME_CATEGORIES.live,
  GAME_CATEGORIES.blackjack,
  GAME_CATEGORIES.fruits,
  GAME_CATEGORIES['bonus-games'],
  GAME_CATEGORIES.books,
  GAME_CATEGORIES.dropwins
]);

export const gameCategoriesLiveGroup: GameCategoryParams[] = (() => {
  const liveGames = [
    GAME_CATEGORIES.slots,
    GAME_CATEGORIES.live,
    GAME_CATEGORIES['blackjack-league'],
    GAME_CATEGORIES.blackjack,
    GAME_CATEGORIES.baccarat,
    GAME_CATEGORIES.poker,
    GAME_CATEGORIES.roulette
  ];
  if (!isBjLeagueVisible()) {
    return liveGames.filter((category) => category.path !== '/games/blackjack-league');
  }

  return liveGames;
})();

export const gameCategoriesJackpots: GameCategoryParams[] = [
  GAME_CATEGORIES.slots,
  GAME_CATEGORIES.jackpots,
  GAME_CATEGORIES['jackpot-mini'],
  GAME_CATEGORIES['jackpot-middle'],
  GAME_CATEGORIES['jackpot-grand']
];

export type SidebarGamesDropdownOption = {
  title: string;
  icon: JSX.Element;
  menuItems: GameCategoryParams[];
};

export const sidebarCasinoOption: SidebarGamesDropdownOption = {
  title: 'gameCategories.casino',
  icon: <CasinoIcon />,
  menuItems: addHalloWeenCategory([
    // GAME_CATEGORIES.easter,
    GAME_CATEGORIES.all,
    GAME_CATEGORIES.top,
    GAME_CATEGORIES.new,
    GAME_CATEGORIES.megaways,
    GAME_CATEGORIES['bonus-games'],
    GAME_CATEGORIES.dropwins,
    GAME_CATEGORIES.slots,
    GAME_CATEGORIES.fruits,
    GAME_CATEGORIES.books
  ])
};

export const sidebarLiveOption: SidebarGamesDropdownOption = (() => {
  const liveGames = [
    GAME_CATEGORIES['all-live'],
    GAME_CATEGORIES['blackjack-league'],
    GAME_CATEGORIES.blackjack,
    GAME_CATEGORIES.baccarat,
    GAME_CATEGORIES.poker,
    GAME_CATEGORIES.roulette
  ];

  if (!isBjLeagueVisible()) {
    return {
      title: 'general.allLiveGames',
      icon: <AllLive />,
      menuItems: liveGames.filter((category) => category.path !== '/games/blackjack-league')
    };
  }

  return {
    title: 'general.allLiveGames',
    icon: <AllLive />,
    menuItems: liveGames
  };
})();

export const sidebarJackpotOption: SidebarGamesDropdownOption = {
  title: 'general.jackpotGames',
  icon: <JackpotIcon />,
  menuItems: [
    GAME_CATEGORIES['all-jackpots'],
    GAME_CATEGORIES['jackpot-mini'],
    GAME_CATEGORIES['jackpot-middle'],
    GAME_CATEGORIES['jackpot-grand']
  ]
};
