import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Bonus,
  DepositPaymentMethodOption,
  makeFloat,
  useGetBonusesTransactions,
  useGetDepositMethods,
  useGetFullInfo,
  useGetShortBalance,
  useMakeDeposit,
  useMakeRedirectDeposit,
  validateTransactionValue
} from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import { Dropdown } from 'src/components/Dropdowns/Dropdown';
import { Input } from 'src/components/Inputs/Input';
import { Spin } from 'src/components/svg';
import {
  FieldType,
  useForm,
  validateMaxLength,
  validateNumbersString,
  validatePositiveNumber,
  validateRequired
} from 'src/hooks/useForm';
import { useModalContext } from 'src/hooks/useModalContext';
import { useAmountPresets } from 'src/hooks/walletDepositHooks/useAmountPreset';
import { PaymentMethods } from 'src/pages/AccountWallet/components/PaymentMethods';
import { PaymentMethod } from 'src/types';
import { getCurrencyIcon, prepareMethodOptions, prepareVoucherOptions } from 'src/utils';
import i18n from 'src/locale/config';
import { twJoin } from 'tailwind-merge';
import { CryptoMethods } from '../components/CryptoMethods';
import { TabsMethods } from '../components/TabsMethods';
import { AmountPresets } from './components/AmountPresets';
import { DepositBonuses } from './components/DepositBonuses';
import { DepositPromoCode } from './components/DepositPromoCode';
import { HeaderDeposit } from './components/HeaderDeposit';
import { findClosestNumber, getCurrentValueWithinLimits } from './helpers';
import { LOCAL_STORAGE_KEY_CURRENT_URL } from 'src/constants/storageKey';

interface DepositProps {
  isModal?: boolean;
}

export const Deposit: FC<DepositProps> = ({ isModal = false }) => {
  const depositRef = useRef<HTMLInputElement>(null);
  const { refetch: refetchBonusesTransactions } = useGetBonusesTransactions(
    {
      bonus_type: 'All',
      status: ['Pending']
    },
    { enabled: false, isNeedPages: false }
  );
  const [currentMethods, setCurrentMethods] = useState('basic');
  const { t } = useTranslation();
  const location = useLocation();
  const { openModal, closeModal, openedModalName } = useModalContext();
  const {
    data,
    sortedDepositMethods: sortedPaymentMethods,
    isLoading: isLoadingPaymentMethods
  } = useGetDepositMethods();
  const { mutateAsync, isLoading } = useMakeDeposit();
  const { fullInfo: { email: userEmail } = {} } = useGetFullInfo();
  const makeRedirectDeposit = useMakeRedirectDeposit();
  const balance = useShortBalanceFormatted();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [coin, setCoin] = useState('');
  const [promoCode, setPromoCode] = useState<string>('');
  const [selectedBonus, setSelectedBonus] = useState<Bonus | null>(null);
  const amountPresets = useAmountPresets({ minPayment: data?.min });

  const {
    values,
    errors,
    fieldsProps: { amountProps },
    setValues,
    isFormValid,
    updateDefaultStatePartially
  } = useForm<{ amount: FieldType.Number }>({
    amount: {
      placeholder: `${t('general.minDeposit')}: ${balance.currencySign} ${makeFloat(data?.min)}`,
      fieldType: FieldType.Number,
      validation: [
        validateRequired,
        validateMaxLength(20),
        validateNumbersString,
        validatePositiveNumber,
        validateTransactionValue({
          globalMax: data?.max,
          globalMin: data?.min,
          currency: balance.currencySign,
          selectedPaymentMethod: paymentMethod,
          selectedCoin: coin
        })
      ],
      defaultValue: '100'
    }
  });

  useEffect(() => {
    updateDefaultStatePartially({ amount: amountPresets[1] });
  }, [amountPresets]);

  const handleChangePromoCode = useCallback((code: string) => {
    setPromoCode(code);
  }, []);

  useEffect(() => {
    if (location?.state?.scrollToDeposit) {
      setTimeout(() => {
        depositRef?.current?.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }, [location?.state?.scrollToDeposit]);

  const changeDepositSumm = (value: string) => {
    if (isLoadingPaymentMethods) return;
    setValues({ amount: value });
  };

  const onSelectPaymentMethod = useCallback(
    (value: PaymentMethod) => {
      if (coin) {
        setCoin('');
      }
      value.type === 'voucher' && value.options ? setValues({ amount: '' }) : null;

      setPaymentMethod(value);
      const currentValueWithinLimit = getCurrentValueWithinLimits({
        paymentMethod: value,
        globalMin: data?.min,
        globalMax: data?.max,
        currentValue: amountProps.value,
        amountPresets
      });

      if (value.type === 'voucher' && value.options) {
        const amountsArray: number[] = value.options
          .map((option) => option.amount)
          .filter((amount) => typeof amount === 'number') as number[];

        const closestAmount = findClosestNumber(amountsArray, currentValueWithinLimit);

        updateDefaultStatePartially({
          amount: String(closestAmount)
        });
        return;
      }

      updateDefaultStatePartially({
        amount: String(currentValueWithinLimit)
      });
    },
    [
      amountProps.value,
      coin,
      data?.max,
      data?.min,
      setValues,
      updateDefaultStatePartially,
      amountPresets
    ]
  );

  const onSelectCryptoPaymentMethod = useCallback(
    (methodName: DepositPaymentMethodOption['name']) => {
      setCoin(methodName || '');
      const currentValueWithinLimit = getCurrentValueWithinLimits({
        globalMin: data?.min,
        globalMax: data?.max,
        currentValue: amountProps.value,
        amountPresets
      });
      updateDefaultStatePartially({
        amount: String(currentValueWithinLimit)
      });
    },
    [amountProps.value, data?.max, data?.min, updateDefaultStatePartially, amountPresets]
  );

  const handleVoucherDropdownChange = (element: string | undefined) => {
    setValues({ amount: element || '0' });
  };

  const handleSubmitClick = useCallback(async () => {
    const method = coin ? `${paymentMethod?.method}_${coin}` : paymentMethod?.method;
    const methodForModal = paymentMethod?.type;

    /** При вызове депозита и переадоресации пользователя на сервис пополнения,
     * сохраняем текущий url страницы что бы потом вернуть на неё пользователя  */
    localStorage.setItem(LOCAL_STORAGE_KEY_CURRENT_URL, location.pathname);

    const objectToSend = {
      method: method!,
      amount: parseInt(values.amount!, 10) * 100,
      promocode: promoCode,
      language: i18n.language,
      email: userEmail
    };
    const response = await mutateAsync(objectToSend);
    refetchBonusesTransactions();
    if (!response) {
      return;
    }
    if (methodForModal === 'coin') {
      openedModalName === 'POPUP_DEPOSIT' && closeModal();
      openModal('DEPOSIT_CRYPTO', { props: response.data });
    }
    if (response?.data?.method?.toUpperCase() === 'POST' && response?.data?.formFields) {
      openedModalName === 'POPUP_DEPOSIT' && closeModal();
      openModal('FORM_FIELDS', {
        props: {
          ...response.data,
          paymentMethodName: paymentMethod?.title
        }
      });
    } else if (response?.data?.method === 'POST' && response?.data?.params) {
      makeRedirectDeposit.mutate({
        params: response.data.params,
        url: response.data.checkoutUrl
      });
    } else if (response?.data?.checkoutUrl) {
      setTimeout(() => {
        window.open(response.data.checkoutUrl, '_self');
      });
    }
  }, [
    coin,
    paymentMethod?.method,
    paymentMethod?.type,
    paymentMethod?.title,
    values.amount,
    promoCode,
    userEmail,
    mutateAsync,
    refetchBonusesTransactions,
    openedModalName,
    closeModal,
    openModal,
    makeRedirectDeposit
  ]);

  const handleSelectBonus = (bonus: Bonus | null) => {
    setSelectedBonus(bonus);

    if (selectedBonus) setPromoCode('');
    if (bonus?.promocode) setPromoCode(bonus.promocode);

    if (
      bonus &&
      getMinDepositOfBonus({ bonus, currency: balance.currency }) > Number(amountProps.value)
    ) {
      return setValues({
        amount: `${getMinDepositOfBonus({ bonus, currency: balance.currency })}`
      });
    }

    return setValues({ amount: `${amountProps.value}` });
  };

  const selectCryptoPaymentMethod = () => {
    setPaymentMethod(sortedPaymentMethods?.find(({ type }) => type === 'coin') as PaymentMethod);
  };

  useEffect(() => {
    if (
      selectedBonus &&
      getMinDepositOfBonus({ bonus: selectedBonus, currency: balance.currency }) >
        Number(amountProps.value)
    ) {
      setSelectedBonus(null);
      setPromoCode('');
    }
  }, [amountProps.value, balance.currency, selectedBonus]);

  const paymentMethods = sortedPaymentMethods?.filter(({ type }) => type !== 'coin');

  const onSelectCoinType = (element?: string) => {
    element && setCoin(element);
  };

  return (
    <div
      className={twJoin(
        'flex flex-col 640:gap-7 gap-9 w-full',
        isModal ? 'min-h-full' : 'px-2.5 768:px-12'
      )}>
      <div
        className={twJoin(
          'h-full flex flex-col 568:pt-5 768:px-5 pt-2.5 px-2.5 overflow-y-auto gap-y-2.5',
          isModal ? 'rounded-none' : 'rounded-[20px] bg-gradient'
        )}
        ref={depositRef}
        id="depositPromocode">
        <HeaderDeposit currencySign={balance.currencySign} amount={balance.amount} />
        <TabsMethods
          isLoadingPaymentMethods={isLoadingPaymentMethods}
          setCurrentMethods={setCurrentMethods}
          currentMethods={currentMethods}
          setPaymentMethod={setPaymentMethod}
          resetCoin={() => setCoin('')}
          selectCryptoPaymentMethod={selectCryptoPaymentMethod}
        />
        {currentMethods === 'crypto' ? (
          <CryptoMethods
            sortedPaymentMethods={sortedPaymentMethods}
            coin={coin}
            onSelectCryptoPaymentMethod={onSelectCryptoPaymentMethod}
          />
        ) : (
          <PaymentMethods
            methods={paymentMethods as PaymentMethod[]}
            onSelectPaymentMethod={onSelectPaymentMethod}
            selectedMethod={paymentMethod?._reactDepositMethodId}
          />
        )}

        {(paymentMethod?.type === 'voucher' && !paymentMethod?.options) ||
        paymentMethod?.type !== 'voucher' ? (
          <AmountPresets
            amountPresets={amountPresets}
            onClick={changeDepositSumm}
            value={values.amount}
          />
        ) : null}

        {paymentMethod?.type !== 'voucher' &&
        paymentMethod?.type !== 'coin' &&
        paymentMethod?.options &&
        paymentMethod?.options.length ? (
          <div className="z-[22]">
            <Dropdown
              value={coin}
              onChange={onSelectCoinType}
              placeholder={`${t('accountDeposit.select')} ${paymentMethod.type}`}
              options={prepareMethodOptions(paymentMethod)}
            />
          </div>
        ) : null}

        {paymentMethod?.type !== 'voucher' ||
        (paymentMethod?.type === 'voucher' && !paymentMethod.options?.length) ? (
          <>
            <Input
              {...amountProps}
              disabled={isLoadingPaymentMethods}
              autoComplete="off"
              containerClassName="h-10 py-0"
              className="!text-[26px] text-center font-bold placeholder:text-center"
              rightIcon={
                <div className="jmid:text-[26px] jmid:font-bold">{balance.currencySign}</div>
              }
            />
            {errors.amount && <span className="text-[red] text-xs">{errors.amount}</span>}
          </>
        ) : null}

        {paymentMethod?.type === 'voucher' && paymentMethod?.options && (
          <div className="z-[21] pt-2.5">
            <Dropdown
              value={values.amount}
              options={prepareVoucherOptions(paymentMethod?.options)}
              onChange={handleVoucherDropdownChange}
              placeholder={`${t('general.minDeposit')}: ${balance.currencySign} ${makeFloat(
                data?.min
              )}`}
            />
          </div>
        )}
        <div className="flex flex-col gap-2.5">
          <Button
            disabled={
              currentMethods === 'basic' ? !isFormValid || !paymentMethod : !isFormValid || !coin
            }
            mode="button-secondary"
            className="w-full !max-w-full 1024:!max-w-[300px] py-2 h-[50px] flex-col text-center self-center"
            onClick={handleSubmitClick}
            label={`${t('accountWalletBalance.deposite')}`}>
            <div className="flex items-center gap-1">
              {isLoading && <Spin />}
              {`${t('accountWalletBalance.deposite')}`}
              {selectedBonus && (
                <p className="text-[14px] font-normal">
                  + bonus {selectedBonus.percent}%
                  {selectedBonus.freeSpins ? ` + ${selectedBonus.freeSpins} FS` : null}
                </p>
              )}
            </div>
          </Button>
          <DepositBonuses
            handleSelectBonus={handleSelectBonus}
            selectedBonus={selectedBonus}
            isModal={isModal}
          />
          <DepositPromoCode
            value={promoCode}
            changePromoCode={handleChangePromoCode}
            placeholder={`${t('general.placeholderPromo')}`}
            isModal={isModal}
          />
        </div>
      </div>
    </div>
  );
};

export function useShortBalanceFormatted() {
  const shortBalance = useGetShortBalance();

  const currencySign = useMemo(() => {
    if (!shortBalance?.data?.currency) return '€';
    return getCurrencyIcon(shortBalance?.data?.currency);
  }, [shortBalance?.data?.currency]);

  const currencyName = useMemo(() => {
    if (!shortBalance?.data?.currency) return 'EUR';
    return shortBalance?.data?.currency;
  }, [shortBalance?.data?.currency]);

  const currentBalance = useMemo(() => {
    if (!shortBalance?.data?.balance === undefined) return 'N/A';
    return makeFloat(shortBalance?.data?.balance);
  }, [shortBalance?.data?.balance]);

  return {
    currency: currencyName,
    currencySign,
    data: useMemo(() => `${currencySign} ${currentBalance}`, [currencySign, currentBalance]),
    amount: currentBalance
  };
}

type getMinDepositOfBonusProps = { bonus: Bonus | null; currency: string };

function getMinDepositOfBonus({ currency, bonus }: getMinDepositOfBonusProps) {
  return parseFloat(makeFloat(bonus?.minDepositAmount[currency]));
}
