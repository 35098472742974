import { useNavigate } from 'react-router-dom';
import { SingleNavbarItem } from '../SingleNavbarItem';
import { useDrawer } from 'src/hooks/useDrawer';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { SportIcon } from 'src/components/svg/SportIcon';
import { LiveBetIcon } from 'src/components/svg/LiveBetIcon';
import { twJoin } from 'tailwind-merge';

interface SportLinksProps {
  activeCategory: {
    GAME: boolean;
    LIVE: boolean;
    VIP: boolean;
    PROVIDERS: boolean;
    SPORT: boolean;
    LIVEBET: boolean;
  };
}

export const SportLinks: FC<SportLinksProps> = ({ activeCategory }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeProvidersSearch } = useDrawer();

  const handleSportClick = () => {
    navigate('/sport');
    // navigate('/test-sport-url');
    closeProvidersSearch?.();
  };

  const handleLiveBettingClick = () => {
    // navigate('/test-sport-url/live');
    navigate('/sport/live');
    closeProvidersSearch?.();
  };

  return (
    <>
      <SingleNavbarItem
        onClick={handleSportClick}
        textHighlightCondition={activeCategory.SPORT}
        title={t('general.sport')}>
        <SportIcon className={twJoin('w-[26px] h-auto', activeCategory.SPORT && 'fill-yellow')} />
      </SingleNavbarItem>
      <SingleNavbarItem
        onClick={handleLiveBettingClick}
        textHighlightCondition={activeCategory.LIVEBET}
        title={t('gameCategories.live')}>
        <LiveBetIcon
          className={twJoin('w-[26px] h-auto', activeCategory.LIVEBET && 'fill-yellow')}
        />
      </SingleNavbarItem>
    </>
  );
};
