import { Jackpot } from 'react-easyrocket';
import {
  GAME_CATEGORIES,
  gameCategoriesJackpots,
  gameCategoriesLiveGroup,
  gameCategoriesMainList
} from 'src/constants/gameCategories';

export const getCategories = (jackpots: Jackpot[], pathname: string) => {
  if (pathname.includes('jackpot')) {
    return gameCategoriesJackpots.filter((item) => {
      return jackpots.some(
        (jackpot) =>
          (item.title.substring(item.title.indexOf('.') + 1) ===
            jackpot.code.substring(jackpot.code.indexOf('-') + 1) &&
            jackpot.jackpotInfo) ||
          item.title.endsWith('slots') ||
          item.title.endsWith('jackpots')
      );
    });
  }

  if (
    gameCategoriesLiveGroup.some(
      (item) => location.pathname === item.path && item.param !== GAME_CATEGORIES.slots.param
    )
  ) {
    return gameCategoriesLiveGroup;
  }

  return gameCategoriesMainList;
};
