import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { useApiContext } from 'react-easyrocket';
import { useModalContext } from 'src/hooks/useModalContext';
import { useNavigate } from 'react-router-dom';

interface SmarticoButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  isSoonLabel?: boolean;
  isCollapsed?: boolean;
  label?: ReactNode;
  icon: string;
  shouldDepositFirst: boolean;
  action?: string;
  navigate?: string;
}

export const SmarticoButton: FC<SmarticoButtonProps> = ({
  isSoonLabel,
  isCollapsed,
  shouldDepositFirst,
  label,
  icon,
  className,
  action,
  navigate: navigateUrl
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isAuthenticated && !navigateUrl) {
      openModal('SIGNUP');
      return;
    }

    if (shouldDepositFirst) {
      openModal('SMARTICO_DEPOSIT_FIRST');
      return;
    }

    if (isSoonLabel) {
      openModal('SMARTICO_STUB_MODAL');
      return;
    }

    if (action && window._smartico) {
      window._smartico.dp(action);
    }

    if (navigateUrl) {
      navigate(navigateUrl);
    }
  };

  return (
    <button
      className={twJoin(
        'flex gap-1 hover:text-yellow relative items-center justify-center bg-smartico_bg text-sm font-bold text-white',
        isCollapsed ? 'p-4 hover:bg-smartico_bg_hover' : 'px-2 py-2.5 rounded-[20px]',
        className
      )}
      onClick={handleClick}>
      <img src={icon} alt="icon" className="w-6 h-6" width={24} height={24} />
      {!isCollapsed && <span className="text-left first-letter:uppercase">{label}</span>}
      {isSoonLabel && !isCollapsed && (
        <span
          className="p-1 text-[8px] absolute leading-[100%] bg-SMARTICO_SOON_BG uppercase -top-2 right-2 text-smartico_soon_text rounded-[4px]"
          style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
          {t('smartico.soon')}
        </span>
      )}
    </button>
  );
};
