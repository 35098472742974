import { LocalTournamentMember, getCurrencyIcon, makeFloat } from 'react-easyrocket';

export const TOURNAMENT_QUANTITATIVE_TASK_TYPES = [1, 3, 5];

export const getMemberProgressValue = (
  progress: LocalTournamentMember['progress'] | number
): number => {
  if (Array.isArray(progress) && typeof progress[0] === 'object') return progress[0].progress;
  if (typeof progress === 'number') return progress;
  return 0;
};

export const getMemberProgressCurrency = (progress: LocalTournamentMember['progress'] | number) => {
  if (Array.isArray(progress) && progress[0]?.currency) return progress[0]?.currency;
  return '';
};

export const tournamentProgressFormatter = (
  type: number,
  taskType: number,
  progress: LocalTournamentMember['progress'] | number,
  currency?: string
): string => {
  const progressValue = getMemberProgressValue(progress);
  const currencyValue = currency || getMemberProgressCurrency(progress);

  if (type === 0) {
    return `${progressValue}%`;
  }
  if (type === 1) {
    if (!TOURNAMENT_QUANTITATIVE_TASK_TYPES.includes(taskType) && currencyValue) {
      return `${getCurrencyIcon(currencyValue)} ${makeFloat(progressValue)}`;
    } else {
      return String(progressValue);
    }
  }
  return '';
};
