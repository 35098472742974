import { useApiContext, useGetDepositMethods, useTrackReferralLink } from 'react-easyrocket';
import { useDoSomethingOnEveryPage } from 'src/hooks/useDoSomethingOnEveryPage';
import { useSetSmarticoUser } from 'src/hooks/useSetSmarticoUser';
import { useTrackIncomingBonusTransaction } from 'src/hooks/useTrackIncomingBonusTransaction';
import { useJackpotWin } from '../Modal/JackpotWinModal/useJackpotWin';
import { useTournamentWin } from 'src/hooks/useTournamentWin';
import { useOpenDepositOnSessionStart } from 'src/hooks/useOpenDepositOnSessionStart';
import { useCentrifugeMessages } from 'src/hooks/useCentrifugeMessages';
import { useActivateAuthBonus } from 'src/hooks/useActivateAuthBonus';
import { useSetUserLanguage } from 'src/hooks/useSetUserLanguage';
import { useEffect } from 'react';
import { resetPreloadRetries } from 'src/utils/preloadRetry';
import { smarticoLogout } from 'src/utils/smartico';
import { useModalContext } from 'src/hooks/useModalContext';

export const SideEffects = () => {
  const { openModal } = useModalContext();
  useDoSomethingOnMount();
  useDoSomethingOnEveryPage();
  useDoSomethingOnLogout();
  useSetSmarticoUser();

  useTrackIncomingBonusTransaction();
  useJackpotWin();
  useTournamentWin();
  useGetDepositMethods();
  useOpenDepositOnSessionStart();
  useTrackReferralLink({ openSignup: () => openModal('SIGNUP') });
  useCentrifugeMessages();
  useActivateAuthBonus();
  useSetUserLanguage();

  return null;
};

function useDoSomethingOnMount() {
  useEffect(() => {
    resetPreloadRetries();
  }, []);
}

function useDoSomethingOnLogout() {
  const { isAuthenticated } = useApiContext();

  useEffect(() => {
    if (!isAuthenticated) {
      smarticoLogout();
    }
  }, [isAuthenticated]);
}
