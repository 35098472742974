import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TOTAL_GAMES } from 'src/components/GamesGrid/GamesGridLegacy';
import { CardSeeAll } from './components/CardSeeAll';
import { useGamesGridConfig, useGetCombinedGames } from 'react-easyrocket';
import { GAME_CATEGORIES, GameCategoryName } from 'src/constants/gameCategories';
import { GamesGrid } from '../GamesGrid';

type Props = {
  categoryId: GameCategoryName;
  className?: string;
};

export function GamesBlock({ categoryId }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { gamesToShow } = useGamesGridConfig();
  const { games, isFetching, isFetched } = useGetCombinedGames(
    {
      per_page: TOTAL_GAMES
    },
    { categoryId }
  );
  const navigateToAllGamesInCategory = () => navigate(`/games/${categoryId}`);

  return (
    <div className="px-5">
      <p className="text-text-secondary 768:text-4xl font-bold leading-6 text-2xl py-4">
        {t(GAME_CATEGORIES[categoryId].title)}
      </p>
      <GamesGrid
        isFetched={isFetched}
        isFetching={isFetching}
        games={games.slice(0, gamesToShow)}
        lastItem={<CardSeeAll onClick={navigateToAllGamesInCategory} totalGames={'7325'} />}
      />
    </div>
  );
}
