import { t } from 'i18next';
import { FC, memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

interface MenuElementProps {
  path: string;
  image: JSX.Element;
  title: string;
  isPromo?: boolean;
  isEvent?: boolean;
  menuElementClickHandler: () => void;
}

const MenuElement: FC<MenuElementProps> = ({
  path,
  image,
  title,
  isPromo = false,
  menuElementClickHandler
}) => {
  const { pathname } = useLocation();

  return (
    <NavLink
      key={title}
      onClick={menuElementClickHandler}
      className={({ isActive }) =>
        twJoin(
          'w-full flex items-center rounded-lg bg-bg-sidebar-menu text-sm font-semibold hover:text-yellow group',
          isActive && pathname === path && 'text-yellow [&_path]:fill-yellow',
          isPromo && 'p-px bg-gradient-to-r hover:bg-gradient-to-l from-[#7300fe] to-[#f186ed]'
        )
      }
      to={path}>
      <div className={twJoin(isPromo && 'bg-[#4c1c81]' ,'w-full flex items-center gap-2.5 p-3 rounded-lg text-sm font-semibold hover:text-yellow group')}>
        <div className="[&>svg]:w-5 [&>svg]:h-5 group-hover:[&_path]:fill-yellow">{image}</div>
        <p className="first-letter:uppercase text-left">{t(title)}</p>
      </div>
    </NavLink>
  );
};

export default memo(MenuElement);
