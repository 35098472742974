import { FC } from 'react';
import { PasswordInput } from 'src/components/Inputs/PasswordInput';
import { PaymentFormField } from 'react-easyrocket';

interface PasswordInputComponentProps {
  field: PaymentFormField;
  defaultValue: string;
}

export const PasswordInputComponent: FC<PasswordInputComponentProps> = ({
  field,
  defaultValue
}) => {
  return (
    <PasswordInput
      required={field.toShow}
      label={field.title}
      name={field.name}
      defaultValue={defaultValue}
      labelClassname="!text-[black]"
      placeholder={field.title}
      overrideContainerClassName={field.toShow ? '' : 'hidden'}
      labelWithPadding={true}
      autoComplete="new-password"
    />
  );
};
