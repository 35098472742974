import { SmarticoButton } from 'src/components/SmarticoButton';
import { smarticoButtons } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useApiContext, useGetTransactions } from 'react-easyrocket';
import { Separator } from 'src/components/Separator/Separator';
import { SkeletonOfAnySize } from 'src/components/Skeletons';

export const SmarticoButtons = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useApiContext();

  const { arrayTransactions, isFetching } = useGetTransactions({
    type: ['Deposit', 'ChangeBalanceInc'],
    status: ['Complete', 'Rejected', 'PendingWager', 'WagerZeroing']
  });

  const shouldDepositFirst = !Boolean(arrayTransactions.length);

  if (isFetching && isAuthenticated)
    return (
      <>
        <Separator width="w-full" noMarginTop />
        <div className="grid grid-cols-2 gap-y-2 gap-x-1 w-full">
          <SkeletonOfAnySize className="w-full h-11" />
          <SkeletonOfAnySize className="w-full h-11" />
          <SkeletonOfAnySize className="w-full h-11" />
          <SkeletonOfAnySize className="w-full h-11" />
          <SkeletonOfAnySize className="w-full h-11" />
        </div>
      </>
    );

  return (
    <>
      <Separator width="w-full" noMarginTop />
      <div className="grid grid-cols-2 gap-y-2 gap-x-1 w-full">
        {smarticoButtons.map(({ key, label, icon, isSoonLabel, action, navigate }, index) => (
          <SmarticoButton
            key={key}
            shouldDepositFirst={shouldDepositFirst}
            label={t(label)}
            icon={icon}
            isSoonLabel={isSoonLabel}
            action={action}
            navigate={navigate}
            className={index === 0 ? 'col-span-full' : undefined}
          />
        ))}
      </div>
    </>
  );
};
