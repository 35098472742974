import { useTranslation } from 'react-i18next';
import { ThinClose } from '../svg';

type Props = {
  onChange?: (value: string) => void;
  recentSearches: string[];
  deteleRecentSearch?: (deletedValue: string) => void;
};

export const RecentSearches = ({ onChange, deteleRecentSearch, recentSearches }: Props) => {
  const { t } = useTranslation();

  if (!recentSearches.length) {
    return null;
  }

  const deleteHandle = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, item: string) => {
    event.stopPropagation();
    deteleRecentSearch?.(item);
    onChange?.('');
  };

  return (
    <div className="text-gcs_recent_searches_text w-full pb-2.5">
      <div className="768:text-[36px] font-bold text-[18px] mb-2.5">
        {t('general.recentSearches')}
      </div>
      <div className="flex gap-[5px] overflow-x-auto">
        {recentSearches.map((item, index) => (
          <div
            className="flex justify-between items-center p-[13px] border rounded-lg border-gcs_recent_searches_border cursor-pointer hover:border-gcs_recent_searches_border_hover font-medium whitespace-nowrap"
            key={index}
            onClick={() => recentSearches[index] && onChange?.(recentSearches[index])}>
            <p className="mr-2.5">{item}</p>
            <ThinClose onClick={(e) => deleteHandle(e, item)} />
          </div>
        ))}
      </div>
    </div>
  );
};
