import { CSSProperties, FC, useMemo } from 'react';
import { useGetBonuses } from 'react-easyrocket';
import { CONTENT_URL } from 'src/api';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import { useTranslation } from 'react-i18next';

export const BlockDeposit: FC = () => {
  const { data } = useGetBonuses();
  const { t } = useTranslation();

  const signUpBonus = useMemo(() => {
    return data.find((bonus) => bonus.tag === 'vip-pack');
  }, [data]);

  if (data.length === 0) {
    return null;
  }

  const style = {
    '--image-desktop': `url(${CONTENT_URL}${signUpBonus?.imageBackground})`,
    '--image-mobile': `url(${CONTENT_URL}${signUpBonus?.imageBanner})`
  } as CSSProperties;

  return (
    <div className="px-5">
      <div
        className="relative filter-shadow flex flex-col items-end min-h-[380px] w-full justify-end bg-no-repeat 568:bg-left bg-cover rounded-[20px] mt-10 bg-[10%] 768:min-h-[450px] 768:bg-left 768:mt-5 1024:mt-10 768:items-end 768:justify-center bg-[image:var(--image-mobile)] 1024:bg-[image:var(--image-desktop)]"
        style={style}>
        <div className="flex gap-2.5 flex-col max-w-xs justify-center h-full z-10 w-2/4 mx-4 768:mt-11 1024:pr-[100px] 768:pr-[60px] 768:mx-0 768:h-auto 768:max-w-none">
          {signUpBonus?.name && (
            <div
              className="font-bold text-bd_name_bonus_sign_up text-4xl 568:text-3xl 568:mt-0 mt-[90px] cms-dropdown 768:text-4xl 1024:text-5xl 768:leading-[120%]"
              dangerouslySetInnerHTML={{ __html: signUpBonus?.name }}></div>
          )}
          {signUpBonus?.shortDescription && (
            <div
              className="font-normal text-bd_short_description_bonus_sign_up 568:text-2xl short-text text-lg 768:text-xl 1024:text-2xl strong cms-dropdown"
              dangerouslySetInnerHTML={{ __html: signUpBonus?.shortDescription }}></div>
          )}
          <Link
            to={`/bonuses/${signUpBonus?.id}`}
            state={{ id: signUpBonus?.id }}
            aria-label="start winning"
            className="mt-4 768:mt-10">
            <Button
              mode="button-secondary"
              className="w-auto 568:px-4 px-3 mb-7 leading-none 768:px-6 768:mb-0 768:leading-[auto]"
              title={`${t('general.startWinning')}`}>
              {t('general.startWinning')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
