import { MakeDepositResponse } from 'react-easyrocket';
import { FormGenerator } from '../FormGenerator';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';

type FormFieldsModalProps = MakeDepositResponse & {
  paymentMethodName?: string;
};

export const FormFieldsModal = () => {
  const { modalProps } = useModalContext<FormFieldsModalProps>();

  return (
    <ModalWrapper>
      <div className="px-[15px] py-[20px] w-[300px] jxsm:w-full">
        <FormGenerator
          formFields={modalProps?.formFields}
          checkoutUrl={modalProps?.checkoutUrl}
          transaction={modalProps?.transaction}
          paymentMethodName={modalProps?.paymentMethodName}
        />
      </div>
    </ModalWrapper>
  );
};
