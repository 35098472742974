import classNames from 'classnames';
import { memo, useRef } from 'react';
import { useDropdown } from 'src/hooks/useDropdown';
import { Arrow } from '../svg';
import { useLanguageNavigate } from 'react-easyrocket';
import { LanguageDropDownList } from './components/LanguageDropDownList';
import { useLanguageChange } from 'src/locale/hook/useLanguageChange';
import { SupportedLanguages } from 'src/locale/types';

export const CompactLanguageSelector = memo(() => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { language, handleChangeLanguage, currentLanguageIcon, currentLanguageName } =
    useLanguageChange();

  const setLanguageToUrl = useLanguageNavigate();

  const { toggle, showList } = useDropdown({
    value: language
  });

  const onChangeLanguage = (language: keyof SupportedLanguages) => {
    handleChangeLanguage(language);
    setLanguageToUrl(language);
  };

  return (
    <>
      <div
        ref={dropdownRef}
        onClick={toggle}
        role="button"
        tabIndex={0}
        className="flex flex-col justify-center items-center gap-2">
        <p className="[&>svg]:w-6 [&>svg]:h-6">{currentLanguageIcon}</p>
        <Arrow
          className={classNames(
            'w-[15px] stroke-white stroke-[3px] transition-all',
            showList ? '-rotate-180' : 'rotate-0'
          )}
        />
      </div>

      {showList && (
        <LanguageDropDownList
          compact
          currentLanguageName={currentLanguageName}
          onChangeLanguage={onChangeLanguage}
        />
      )}
    </>
  );
});

CompactLanguageSelector.displayName = 'CompactLanguageSelector';
