import { FC } from 'react';
import { ARRAY_LANGUAGES } from 'src/locale/LanguagesAdjustable';
import { twJoin } from 'tailwind-merge';
import { Language, SupportedLanguages } from 'src/locale/types';
import './styles.css';

interface LanguageDropDownListProps {
  compact?: boolean;
  onChangeLanguage: (language: keyof SupportedLanguages) => void;
  currentLanguageName: Language['labelName'];
}

export const LanguageDropDownList: FC<LanguageDropDownListProps> = ({
  compact,
  onChangeLanguage,
  currentLanguageName
}) => {
  const stylesWrapper = compact ? 'wrapperCompactLanguages' : 'wrapperFullLanguage';
  const stylesButton = compact ? 'languageItemCompactLanguages' : 'languageItemFullLanguage';

  return (
    <div className={stylesWrapper}>
      {ARRAY_LANGUAGES.map(({ labelName, labelCode, icon }) => (
        <button
          className={twJoin(stylesButton, currentLanguageName === labelName && 'text-yellow')}
          onClick={() => onChangeLanguage(labelCode)}
          key={labelName}>
          <div className="[&>svg]:w-6 [&>svg]:h-6">{icon}</div>
          {compact ? labelCode : labelName}
        </button>
      ))}
    </div>
  );
};
