import { useLayoutEffect } from 'react';
import {
  useGetCombinedGames,
  useGetGames,
  useInfiniteScroll,
  useRecentSearches,
  useSearch
} from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { InputSearch } from 'src/components/Inputs/InputSearch';
import { RecentSearches } from '../RecentSearches';
import { SearchIcon } from 'src/components/svg';
import { MIN_SEARCH_VALUE_LENGTH } from 'src/constants';
import { GamesGrid } from 'src/components/GamesGrid';
import { NoGamesStub } from '../NoGamesStub';

export const GamesSearch = () => {
  const { recentSearches, saveRecentResult, deleteRecentSearch } = useRecentSearches({
    storageKey: 'recentSearches',
    maxStoredSearchesLength: 20
  });

  const { value, onChange, debouncedValue } = useSearch();

  const { t } = useTranslation();

  const enableRequest = Boolean(debouncedValue && debouncedValue.length >= MIN_SEARCH_VALUE_LENGTH);

  const {
    games: stubGames,
    isFetching: isFetchingStubGames,
    isFetched: isFetchedStubGames
  } = useGetGames({ per_page: 9 }, { target: 'topbarSearchRecommended' });

  const {
    allGames: games,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching
  } = useGetCombinedGames(
    { name: debouncedValue, per_page: 12 },
    { enabled: enableRequest, target: 'topbarSearch' }
  );

  const handleSaveRecentResult = () => {
    if (games.length) {
      saveRecentResult(value, true);
      sessionStorage.setItem('gameSearchValue', value);
    }
  };

  const { parentRef, childRef } = useInfiniteScroll({
    callback: () => {
      if (hasNextPage && value.length >= MIN_SEARCH_VALUE_LENGTH) {
        fetchNextPage();
      }
    }
  });

  const handleRecentSearchClick = (item: string) => {
    onChange(item);
  };

  useLayoutEffect(() => {
    onChange(sessionStorage.getItem('gameSearchValue') || '');
  }, []);

  const gamesFound = games.length > 0;
  const gamesNotFound = games.length === 0 && value.length !== 0 && isFetched;

  return (
    <div ref={parentRef}>
      <InputSearch
        placeholder={t('general.searchGamePlaceholder')}
        value={value}
        onChange={onChange}
        leftIcon={<SearchIcon className="w-5 h-5" fill="#3C404A" />}
        className="flex bg-white items-center rounded-[20px] px-5 py-[13px] w-full mt-2.5"
        classNameInput="text-[#3C404A] text-[16px] px-2.5 focus:outline-none w-full"
        onBlur={handleSaveRecentResult}
      />
      {!value && Boolean(recentSearches?.length) && (
        <RecentSearches
          items={recentSearches}
          handleDelete={deleteRecentSearch}
          handleClick={handleRecentSearchClick}
        />
      )}

      {gamesNotFound && <NoGamesStub>{t('gamesPage.noGamesInCategory')}</NoGamesStub>}

      {gamesFound && (
        <>
          <div className="overflow-auto mt-3">
            <GamesGrid
              isFetching={isFetching}
              isFetched={isFetched}
              className="pb-10"
              games={games}
              onGameClick={handleSaveRecentResult}
            />
            {!isFetching && hasNextPage && (
              <div className="h-5 mb-[70px] w-full" ref={childRef}></div>
            )}
          </div>
        </>
      )}
      <p className="text-text-secondary font-bold text-base mt-3">{t('general.recommended')}</p>

      {stubGames && (
        <div className="overflow-auto mt-2.5">
          <GamesGrid
            isFetching={isFetchingStubGames}
            isFetched={isFetchedStubGames}
            games={stubGames}
            className="mb-[110px]"
          />
        </div>
      )}
    </div>
  );
};
