import { useEffect, useRef } from 'react';
import { useApiContext, useGetFullInfo } from 'react-easyrocket';
import { useTranslation } from 'react-i18next';
import { useSetLanguageToUrl } from 'src/components/LanguagesSelect/hooks/useSetLanguageToUrl';
import { queryClient } from 'src/context/AllProvidersComponent';

export const useSetUserLanguage = () => {
  const { isAuthenticated } = useApiContext();
  const { fullInfo } = useGetFullInfo();
  const { i18n } = useTranslation();
  const shouldRun = useRef<boolean>(true);
  const setLanguageToUrl = useSetLanguageToUrl();

  // change user language if fullInfo.language is different from i18n.langugage
  useEffect(() => {
    if (isAuthenticated) {
      const shouldChangeUserlanguage = fullInfo?.language && i18n.language !== fullInfo?.language;

      if (shouldRun.current && shouldChangeUserlanguage) {
        i18n.changeLanguage(fullInfo?.language);
        setLanguageToUrl();
      }

      if (fullInfo?.language) {
        shouldRun.current = false;
      }
    } else {
      shouldRun.current = true;
      queryClient.removeQueries({ queryKey: ['full-info'] });
    }
  }, [isAuthenticated, fullInfo?.language]);
};
