import { CONTENT_URL } from 'src/api';
import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LocalTournament, useApiContext, useApplyTournament, useCurrency } from 'react-easyrocket';
import { useCallback } from 'react';
import { PrizeTournament } from '../PrizeTournament';
import { useQueryClient } from '@tanstack/react-query';
import { useModalContext } from 'src/hooks/useModalContext';
import ModalWrapper from '../ModalWrapper';
import { useMessage } from 'src/modules/messages';

export const NewLocalTournament = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useApiContext();
  const { currencyName } = useCurrency();
  const { modalProps, closeModal, openModal } = useModalContext<LocalTournament>();
  const { mutateAsync: participateInLocalTournament, isLoading } = useApplyTournament();
  const QueryClient = useQueryClient();
  const { closeMessage } = useMessage();

  const handleParticipateClick = useCallback(async () => {
    if (isAuthenticated) {
      await participateInLocalTournament(
        { tournament_id: modalProps.id },
        {
          onError: (error) => {
            if (error.response?.data && error.response?.data?.code === 16) {
              closeModal();
              closeMessage?.(error.response.data.message || '');
              openModal('TOURNAMENT_LOW_LEVEL');
            }
          }
        }
      );
      closeModal();
    } else {
      closeModal();
      openModal('SIGNUP', {
        props: {
          onSignupSuccess: async () => {
            await participateInLocalTournament(
              { tournament_id: modalProps.id },
              {
                onError: (error) => {
                  if (error.response?.data && error.response?.data?.code === 16) {
                    closeMessage?.(error.response.data.message || '');
                    openModal('TOURNAMENT_LOW_LEVEL');
                  }
                }
              }
            );
            QueryClient.invalidateQueries(['tournament-with-users-progress']);
          }
        }
      });
    }
    navigate(`/tournaments/local/${modalProps.id}?participating=true`);
  }, [
    QueryClient,
    closeMessage,
    closeModal,
    isAuthenticated,
    modalProps.id,
    navigate,
    openModal,
    participateInLocalTournament
  ]);

  const handleShowMoreClick = useCallback(() => {
    navigate(`/tournaments/local/${modalProps.id}`);
    closeModal();
  }, [closeModal, modalProps.id, navigate]);

  return (
    <ModalWrapper>
      <div className="max-w-[400px]">
        <img
          src={`${CONTENT_URL}${modalProps.imageMobile}`}
          className="z-0 relative max-h-[250px]"
          alt="new tournament image"
        />
        <div className="p-[20px] bg-text-secondary z-[1] relative">
          <p className="text-center text-text-primary uppercase text-[20px] font-bold mb-[5px] leading-[110%]">
            {modalProps?.name}
          </p>
          <p className="text-center text-text-primary text-[14px] font-medium leading-[110%]">
            {t('general.prizePool')}:
          </p>
          <div className="text-center text-text-strong text-[26px] font-black leading-[100%] mb-[15px]">
            <PrizeTournament tournament={modalProps} currency={currencyName} />
          </div>
          <p className="text-center text-text-primary text-[14px] font-bold mb-[15px]">
            {modalProps?.descriptionShort}
          </p>
          <Button
            loading={isLoading}
            title={t('general.participate') as string}
            label={t('general.participate') as string}
            mode="button-secondary"
            className="!w-full !max-w-[400px] mb-[15px]"
            onClick={handleParticipateClick}
          />
          <div className="flex gap-3">
            <Button
              title={t('general.cancel') as string}
              label={t('general.cancel') as string}
              mode="button-transparent"
              className="m-auto"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              title={t('general.readMore') as string}
              label={t('general.readMore') as string}
              mode="button-primary"
              className="m-auto"
              onClick={handleShowMoreClick}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
