import { useTranslation } from 'react-i18next';
import man from 'src/assets/images/seeAllMan.webp';
import classNames from 'classnames';

import './styles.css';
import { FC } from 'react';

interface CardSeeAllProps {
  onClick?: () => void;
  totalGames?: string;
}

export const CardSeeAll: FC<CardSeeAllProps> = ({ onClick, totalGames }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => onClick?.()}
      className={classNames(
        'seeAllGamesCard rounded-[10px] text-csa_text relative overflow-hidden cursor-pointer px-2.5 py-2 bg-gradient-to-b from-csa_bg_from to-csa_bg_to'
      )}
      style={{ boxShadow: 'var(--csa_shadow)' }}>
      <div className="flex flex-col items-center justify-center text-center font-bold">
        <p className="text-csa_text_all_games font-bold text-[16px] 640:text-[18px] 640:-mb-2.5 640:mt-[15%]">
          {t('general.allGames')}
        </p>
        <p className="text-[24px] 640:text-[30px]">{totalGames}+</p>
      </div>
      <img
        src={man}
        alt="all games"
        title="all games"
        className={'seeAllGamesCardImage absolute bottom-[-30%] left-0 right-0'}
      />
    </div>
  );
};
