import { useDrawer } from 'src/hooks/useDrawer';
import { NavLink } from 'react-router-dom';
import { EVENT_IMAGES } from 'src/constants/eventImages';

export const Logo = () => {
  const { closeProvidersSearch } = useDrawer();
  return (
    <NavLink
      to="/"
      aria-label="main page"
      className="flex items-center justify-center cursor-pointer w-[45px]"
      onClick={closeProvidersSearch}>
      <img src={EVENT_IMAGES.LOGO.SMALL} alt="logo" className="w-full h-auto object-contain" />
    </NavLink>
  );
};
