import { isProductionEnv } from 'src/api';
import { SUPPORTED_LANGUAGES } from 'src/locale/LanguagesAdjustable';

export const SOFTSWISS_FRAME_DOMAIN = isProductionEnv
  ? 'https://9.ssssdomain.org'
  : 'https://dev.ssssdomain.org';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { au, ...softSwissSupportedLanguages } = SUPPORTED_LANGUAGES;

export const SOFTSWISS_SUPPORTED_LANGUAGES = softSwissSupportedLanguages;

export const SOFTSWISS_TOKEN_REFRESH_INTERVAL = 270000;
