import { useEffect, useRef, useState } from 'react';
import { Button } from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { countriesOptions } from 'src/constants';
import { checkIfFetchDefaultValue } from './utils/checkIfFetchDefaultValue';
import { PaymentFormField } from 'src/types';
import { useProcessHelloclever } from './hook/useProcessHelloclever';
import { Arrow } from '../svg';
import { DepositTransaction, makeFloat, useGetFullInfo } from 'react-easyrocket';
import { PasswordInputComponent } from './components/PasswordInputComponent';
import { InputComponent } from './components/InputComponent';
import { SelectComponent } from './components/SelectComponent';
import { generateSelectOptionsForFormGenerator } from './utils/generateSelectOptionsForFormGenerator';
import { pushAddPaymentInfoToGtmDataLayer } from './utils/pushAddPaymentInfoToGtmDataLayer';
import './styles.css';
import { InputDate } from '../Inputs/InputDate';
import { PhoneInput } from './components/PhoneInput';

export const FormGenerator = ({
  formFields,
  checkoutUrl,
  transaction,
  paymentMethodName
}: {
  formFields?: PaymentFormField[];
  checkoutUrl?: string;
  transaction?: DepositTransaction;
  paymentMethodName?: string;
}) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [visibleBirthdayValue, setVisibleBirthdayValue] = useState('');
  const { fullInfo, isLoading } = useGetFullInfo({
    onSuccess: (response) => {
      setVisibleBirthdayValue(response.data.birthday);
    }
  });
  const [isAccount, setIsAccount] = useState(false);

  const isDevCodeMethod = transaction?.paymentMethod === 'devcode';

  const processIfHelloclever = useProcessHelloclever();

  const handleBirthdayChange = (value: string) => {
    setVisibleBirthdayValue(value);
  };

  useEffect(() => {
    if (!transaction) return;
    const date = new Date();

    pushAddPaymentInfoToGtmDataLayer({
      transaction_id: date.getTime(),
      value: parseInt(makeFloat(transaction.amount, 0), 10),
      currency: transaction.currency
    });
  }, [transaction]);

  return (
    <form
      className="flex flex-col gap-4 m-2.5"
      method="POST"
      action={checkoutUrl}
      ref={formRef}
      onSubmit={(event) => {
        setIsAccount(true);
        processIfHelloclever({ event, transaction, checkoutUrl, paymentMethodName });
      }}>
      <p className="text-2xl jsm:text-xl font-bold whitespace-normal text-center text-button-secondary">
        {t('general.paymentDetails')}
      </p>
      {isLoading || !formFields
        ? null
        : formFields.map((field) => {
            const isSelect = field.type === 'select';
            const isSourceWalletPwd = field.name === 'source_wallet_pwd';
            const isBirthday = field.name === 'birthday';
            const isCountry = field.name === 'country';
            const isNationality = field.name === 'nationality';
            const isPhone = field.name === 'phone';

            if (isSelect) {
              const selectOptions = generateSelectOptionsForFormGenerator(field);
              return (
                <SelectComponent
                  key={field.name}
                  field={field}
                  options={selectOptions}
                  className="input border-input-border rounded-[20px] pl-[15px] pr-2.5 py-2 border"
                />
              );
            }
            if (!isBirthday && !isCountry && !isSelect && !isNationality && !isPhone) {
              return (
                <InputComponent
                  key={field.name}
                  field={field}
                  defaultValue={checkIfFetchDefaultValue(field, fullInfo)}
                />
              );
            }
            if (field.name === 'phone') {
              return (
                <PhoneInput
                  field={field}
                  fullInfo={fullInfo}
                  shouldFetchDefalutValue={checkIfFetchDefaultValue}
                  key={field.name}
                  labelWithPadding
                />
              );
            }
            if (isNationality) {
              return (
                <InputComponent
                  maxLength={2}
                  title={t('general.nationality') as string}
                  key={field.name}
                  field={field}
                  defaultValue={checkIfFetchDefaultValue(field, fullInfo)}
                />
              );
            }
            if (isSourceWalletPwd) {
              return (
                <PasswordInputComponent
                  key={field.name}
                  field={field}
                  defaultValue={checkIfFetchDefaultValue(field, fullInfo)}
                />
              );
            }
            if (isBirthday) {
              return (
                <div key={field.name} className="relative w-full">
                  <input
                    type="text"
                    required
                    value={visibleBirthdayValue}
                    className="absolute bottom-0 left-0 outline-none opacity-0 right-0 rounded-[20px] h-[42px]"
                    inputMode="none"
                  />
                  <InputDate
                    name={field.name}
                    label={field.title}
                    value={visibleBirthdayValue}
                    labelClassName="!text-text-primary"
                    buttonClassName="h-[47px] border-input-border text-text-primary text-[14px]"
                    onChange={handleBirthdayChange}
                    placeholder={field.title}
                    labelWithPadding
                    pickerPosition="top"
                    displayedValueFormat="DD.MM.YYYY"
                    isFullScreenDesktop
                    required={isDevCodeMethod ? true : field.toShow}
                    isDevCodeMethod={isDevCodeMethod}
                  />
                </div>
              );
            }
            if (isCountry) {
              const defaultCountry = fullInfo?.country
                ? countriesOptions.find((el) => el.id === fullInfo.country)?.id
                : undefined;
              return (
                <div className="relative" key={field.name}>
                  <SelectComponent
                    defaultValue={defaultCountry}
                    key={field.name}
                    field={field}
                    options={countriesOptions}
                    className="w-full border-solid rounded-[20px] px-[20px] py-[12px] border bg-text-secondary border-input-border focus:outline-none appearance-none"
                  />

                  <Arrow className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] right-[21px]" />
                </div>
              );
            }
            return null;
          })}
      <Button type="submit" mode="button-secondary" className="mt-[20px]" loading={isAccount}>
        {t('general.confirm')}
      </Button>
    </form>
  );
};

// ----возможно пригодится в будущем - поставить в компонент FormGenerator
// const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
//   e.preventDefault();
//   const formFields: Record<string, any> = {};
//   // @ts-ignore можно получать target напрямую
//   const formData = new FormData(e.target);
//   for (const pair of formData.entries()) {
//     formFields[pair[0]] = pair[1];
//   }
//   axios.post(data.checkoutUrl, formFields, {
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//   });
// };
