import { FullInfoResponse, GameData } from 'react-easyrocket';

export const getAccountInfoErrorMessage = (
  providerId: GameData['providerId'],
  fullInfo: FullInfoResponse
) => {
  if (providerId === 13 && (!fullInfo?.name || !fullInfo?.surname || !fullInfo?.birthday)) {
    return 'general.nonFilledProfile';
  }
  if (providerId === 25 && !fullInfo?.sex && !fullInfo?.birthday) {
    return 'general.nonFilledSexAndBirth';
  }
  if (providerId === 27 && !fullInfo?.birthday) {
    return 'general.nonFilledBirth';
  }
  if (providerId === 101 && !fullInfo?.birthday) {
    return 'general.nonFilledBirth';
  }

  return;
};
