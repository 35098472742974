import { useTranslation } from 'react-i18next';
import { NavigateOptions, useNavigate } from 'react-router-dom';

// TODO: remove when all language based navigation is merged FE-7094
export const useNavigateLng = () => {
  const { i18n } = useTranslation();
  const fallbackLanguage = Array.isArray(i18n.options.fallbackLng)
    ? i18n.options.fallbackLng[0]
    : i18n.options.fallbackLng;

  const defaultLanguage = (fallbackLanguage as string) || 'en';

  const navigate = useNavigate();

  return (to: string | number, options?: NavigateOptions) => {
    if (typeof to === 'number') {
      navigate(to);
      return;
    }

    if (to[0] !== '/') {
      navigate(to, options);
      return;
    }

    const linkWithLanguage = i18n.language !== defaultLanguage ? `/${i18n.language}${to}` : to;

    navigate(linkWithLanguage, options);
  };
};
