import { NavbarDeposit, NavbarSignUp } from '../svg';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../hooks/useDrawer';
import { useLocation } from 'react-router-dom';
import { memo, useCallback, useEffect, useState } from 'react';
import { useApiContext, useDeviceDetect } from 'react-easyrocket';
import { SingleNavbarItem } from './components/SingleNavbarItem';
import { GameCategoryName } from 'src/constants/gameCategories';
import { useModalContext } from 'src/hooks/useModalContext';
import classNames from 'classnames';
import { SlotLinks } from './components/SlotsLinks';
import { ProvidersAndSearch } from './components/ProvidersAndSearch';
import { SportLinks } from './components/SportLinks';
import { useSportContext } from 'src/context/SportProvider';

const sportPage = '/sport/main-page';
const liveBet = '/sport/live';
// const sportPage = '/test-sport-url/main-page';
// const liveBet = '/test-sport-url/live';

const _Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isIos, isMobile } = useDeviceDetect();
  const { isAuthenticated } = useApiContext();
  const { openModal } = useModalContext();
  const { isSportPage } = useSportContext();

  const { isProvidersSearchOpen } = useDrawer();

  const [activeCategory, setActiveCategory] = useState({
    GAME: false,
    LIVE: false,
    VIP: false,
    PROVIDERS: false,
    SPORT: false,
    LIVEBET: false
  });

  useEffect(() => {
    const liveGamesCategories: GameCategoryName[] = [
      'live',
      'blackjack',
      'baccarat',
      'poker',
      'roulette'
    ];

    setActiveCategory({
      PROVIDERS: isProvidersSearchOpen,
      GAME: location.pathname.includes('slots'),
      VIP: location.pathname.includes('vip'),
      LIVE:
        liveGamesCategories.some((word) => location.pathname.includes(word)) &&
        !location.pathname.includes('sport'),
      SPORT: isSportPage && location.pathname.includes(sportPage),
      LIVEBET: isSportPage && location.pathname.includes(liveBet)
    });
  }, [isProvidersSearchOpen, location, isSportPage]);

  const handleCentalButtonClick = useCallback(() => {
    openModal(isAuthenticated ? 'POPUP_DEPOSIT' : 'SIGNUP');
  }, [isAuthenticated, openModal]);

  return (
    <div className="z-20 nav-bar-container">
      <div
        className={classNames(
          'flex items-center justify-around max-h-[100px] w-full px-[5px] py-3 bg-NAV_BAR_BG',
          isMobile && isIos && 'pb-8'
        )}>
        {isSportPage ? (
          <SportLinks activeCategory={activeCategory} />
        ) : (
          <SlotLinks activeCategory={activeCategory} />
        )}

        <SingleNavbarItem
          onClick={handleCentalButtonClick}
          textHighlightCondition={false}
          title={isAuthenticated ? t('general.deposit') : t('general.signUp')}>
          {isAuthenticated ? (
            <NavbarDeposit className="translate-y-2" />
          ) : (
            <NavbarSignUp className="translate-y-2" />
          )}
        </SingleNavbarItem>

        {isSportPage ? (
          <SlotLinks activeCategory={activeCategory} />
        ) : (
          <ProvidersAndSearch activeCategory={activeCategory} />
        )}
      </div>
    </div>
  );
};

export const Navbar = memo(_Navbar);
